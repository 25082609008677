$card-radius: 20px;
$card-shadow: 0px 4px 90px rgba(163,171 ,185,0.24);

.dashboard {
    background-color: #f2f3f6;
    .content {
        padding: 0px 20px 20px 20px;
        overflow-y: auto;
        background-color: #f2f3f6;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        .split-col {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr; } }
    .card {
        border-radius: 20px;
        padding: 20px;
        background-color: #FFF;
        box-shadow: $card-shadow;
        width: 94%;
        height: 100%; }
    .row {
        display: flex;
        justify-content: space-between;
        .col {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%; } }
    .value {
        color: #1F4173;
        font-size: 20px;
        font-weight: bold;
        margin-top: 4px; }
    .label {
        color: #000;
        font-size: 14px;
        margin-top: 9px; }

    .header {
        display: flex;
        justify-content: flex-end;
        height: 90px;
        padding: 30px 20px;
        align-items: center;
        background-color: #f2f3f6;
        .tabs {
            border-radius: $card-radius;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            background: #FFF;
            height: 60px;
            align-items: center;
            box-shadow: 0px 4px 90px rgba(163,171 ,185,0.24);
            p {
                margin: auto 10px;
                padding: 5px 40px;
                border-radius: 8px;
                color: black;
                background-color: rgba(0,0,0,0.05);
                cursor: pointer; }
            .selected {
                cursor: default;
                margin-right: 20px;
                color: #FFF;
                background: #121212; } } } }
