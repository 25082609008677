.popup_mob {
    background: #C4C4C43E;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9999;
    .container {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        .box {
            border-radius: 16px;
            width: 96%;
            min-height: 232px;
            background: #787886 0% 0% no-repeat padding-box;
            box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
            .title {
                font-size: 18px;
                font-weight: 900;
                letter-spacing: 0px;
                color: #010812;
                text-transform: capitalize;
                margin: 18px 24px; }
            .content {
                font-size: 16px;
                // font-weight: 300
                letter-spacing: 0px;
                line-height: 24px;
                color: #000;
                background: transparent;
                box-shadow: none;
                padding: 24px;
                min-height: 104px;
                border-bottom-color: #EBEBEB;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-top-color: #EBEBEB;
                border-top-style: solid;
                border-top-width: 1px;
                margin-top: 0px; }
            .action {
                justify-content: flex-end;
                display: flex;
                margin-top: 18px;
                margin-bottom: 18px;
                margin-right: 10px;
                button {
                    width: auto;
                    font-weight: 800;
                    margin-right: 10px; }
                .no {
                    background: #000 0% 0% no-repeat padding-box;
                    box-shadow: none;
                    border-radius: 8px;
                    font-size: 14px;
                    letter-spacing: -0.38px;
                    color: #FFFFFF;
                    text-transform: capitalize;
                    font-weight: bold; }
                .yes {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: none;
                    border-radius: 8px;
                    font-size: 14px;
                    letter-spacing: -0.38px;
                    color: #000000;
                    text-transform: capitalize;
                    border: 1px solid #000;
                    font-weight: bold; }
                & ant-btn:hover, .ant-btn:focus {
                    color: #000000;
                    background-color: #fff;
                    border: none; } } } } }
