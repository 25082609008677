.select-store-mob {
    display: flex;
    flex-direction: column;
    background: url(../../../assets/images/doodle.svg);
    padding: 20px 0px 0px 0px;
    .error {
        font-size: 12px;
        letter-spacing: 0px;
        color: #BD1616;
        margin-top: 2px; }
    .content {
        margin: 50px 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        flex-grow: 1;
        .title {
            font-size: 24px;
            font-weight: 600;
            color: #121212;
            margin-bottom: 24px; }
        .stores-list {
            display: flex;
            max-width: 357px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            width: 90%;
            .store-card {
                position: relative;
                box-shadow: 0px 3px 26px 0px #00000038;
                background: #fff;
                height: 84px;
                display: flex;
                padding: 20px 17px;
                width: 100%;
                .dp {
                    margin-right: 20px;
                    .not_active {
                        background: #f20808;
                        height: 28px;
                        width: 28px;
                        position: absolute;
                        color: #FFF;
                        font-size: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;
                        padding-bottom: 4px;
                        top: -8px;
                        right: -12px;
                        border: 2px solid #f20808; }
                    .letter {
                        font-size: 24px;
                        background: #121212;
                        color: #FFF;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;
                        height: 50px; }
                    .logo {
                        max-height: 60px;
                        width: 60px; } }
                .type_owner {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #07C180;
                    text-transform: capitalize;
                    margin-top: 6px;
                    font-weight: bold; }
                .type_working, .type_staff {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #0076FF;
                    text-transform: capitalize;
                    margin-top: 6px;
                    font-weight: bold; }
                .name {
                    text-transform: capitalize;
                    font-size: 17px;
                    font-weight: 500;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-break: break-word; } }
            .store:hover {
                -webkit-transform-origin: center bottom;
                -moz-transform-origin: center bottom;
                -o-transform-origin: center bottom;
                -ms-transform-origin: center bottom;
                transform-origin: center bottom;
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -o-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2); } }
        .no_store {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 0;
            flex-direction: column;
            position: relative;
            svg {
                z-index: 90;
                width: 375px !important; } } }
    .footer {
        height: 50px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center; } }
