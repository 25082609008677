.help-center {
    overflow-y: auto;
    height: calc(100vh - 70px);
    .recent-purchased {
        background: #FFF5EB;
        width: 100%;
        height: 36px;
        top: 72px;
        position: fixed;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center; }
    .header {
        padding: 44px 10px 0px 10px;
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #D9D9D9;
            padding: 0px 50px;
            .title {
                font-size: 24px;
                font-weight: 600;
                color: #121212;
                margin-bottom: 28px; }
            .desc {
                font-size: 16px;
                font-weight: 500;
                color: #4E4E4E; }
            .link {
                margin-top: 55px;
                font-size: 16px;
                font-weight: 600;
                cursor: pointer; } } }
    .help {
        margin: 30px 0px;
        padding: 0px 60px;
        .title {
            font-size: 18px;
            font-weight: 600;
            color: #404040; }
        .cards {
            margin-top: 30px;
            display: flex;
            gap: 33px;
            .item {
                width: 370px;
                padding: 20px;
                gap: 20px;
                border-radius: 11px;
                border: 1px solid #E0E6ED;
                box-shadow: 0px 7px 14px 0px #D3DAE233;
                .title {
                    font-size: 20px;
                    font-weight: 700; }
                .desc {
                    font-size: 14px;
                    font-weight: 400;
                    color: #404040;
                    line-height: 22.4px;
                    letter-spacing: 0.14000000059604645px; } } } }
    .quick-links {
        .links {
            margin-top: 30px;
            display: flex;
            gap: 64px;
            .link {
                display: flex;
                gap: 10px;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer; } } } }
.purchase-product-catalog {
    background: #C4C4C43E;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1001;
    .container {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        .box {
            border-radius: 16px;
            width: 582px;
            min-height: 232px;
            background: #fff;
            box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
            .title {
                font-size: 18px;
                font-weight: 900;
                letter-spacing: 0px;
                color: #010812;
                text-transform: capitalize;
                margin: 18px 24px; }
            .content {
                font-size: 16px;
                // font-weight: 300
                letter-spacing: 0px;
                line-height: 24px;
                color: #000;
                background: transparent;
                box-shadow: none;
                padding: 24px;
                min-height: 104px;
                border-bottom-color: #EBEBEB;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-top-color: #EBEBEB;
                border-top-style: solid;
                border-top-width: 1px;
                margin-top: 0px; }
            .action {
                justify-content: flex-end;
                display: flex;
                margin-top: 18px;
                margin-bottom: 18px;
                margin-right: 10px;
                align-items: center;
                .error {
                    margin-right: 10px;
                    color: #FF0000; }
                button {
                    width: auto;
                    font-weight: 800;
                    margin-right: 10px; }
                .purchase {
                    width: 76px;
                    height: 29px;
                    padding: 10px 11px 10px 11px;
                    border-radius: 4px;
                    font-size: 13px;
                    font-weight: 500; }
                .cancel {
                    width: 77px;
                    height: 29px;
                    padding: 10px 18px 10px 18px;
                    border-radius: 4px;
                    border: 1px solid #d9d9d9;
                    font-size: 13px;
                    font-weight: 500; }
                & ant-btn:hover, .ant-btn:focus {
                    color: #000000;
                    background-color: #fff;
                    border: none; } } } }
    .container {
        .box {
            width: 800px;
            height: auto;
            .title {
                font-size: 16px !important;
                font-weight: 600 !important; }
            .content {
                padding: 16px;
                line-height: unset;
                margin: 0px;
                height: auto;
                border-bottom: none; } } }
    .pp-title {
        font-size: 14px;
        font-weight: 500;
        margin: 0px;
        color: #4E4E4E;
        margin-bottom: 14px; }
    .section {
        width: 374px;
        height: 393px;
        border-radius: 10px 0px 0px 0px;
        background: #D9D9D926;
        padding: 10px;
        .num-products {
            margin-top: 35px;
            .np-title {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                .min {
                    margin-left: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #4E4E4E; } }
            .np-input {
                width: 354px;
                height: 36px;
                border: 1px solid #808080;
                margin-top: 9px;
                font-size: 14px;
                font-weight: 500;
                padding: 0px 8px; }
            .np-input::-webkit-outer-spin-button,
            .np-input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0; }
            .np-input {
                -moz-appearance: textfield; }
            .output {
                text-align: right;
                margin-top: 24px;
                font-size: 24px;
                font-weight: 600; } }
        .group {
            margin-bottom: 28px;
            .g-content {
                margin-bottom: 8px;
                .g-title {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 7px; }
                .g-label {
                    font-size: 12px;
                    font-weight: 600;
                    color: #121212; }
                .g-value {
                    font-size: 12px;
                    font-weight: 500;
                    color: #4E4E4E;
                    margin-left: 4px;
                    line-height: 21px; } } } }
    .yes {
        width: 76px !important;
        height: 29px !important;
        padding: 10px 11px 10px 11px;
        gap: 10px !important;
        border-radius: 4px !important;
        font-size: 13px !important;
        font-weight: 500 !important; }
    .no {
        width: 77px !important;
        height: 29px !important;
        padding: 10px 18px 10px 18px !important;
        gap: 10px !important;
        border-radius: 4px !important;
        border: 1px solid #d9d9d9 !important;
        font-size: 13px !important;
        font-weight: 500 !important;
        span {
            padding-top: 1px !important; } } }
.view-purchases {
    .card:last-child {
        margin-bottom: 0px; }
    .card {
        padding: 10px;
        border: 1px solid #E0E6ED;
        box-shadow: 0px 7px 14px 0px #D3DAE233;
        display: flex;
        margin: 0px;
        min-height: 95px;
        margin-bottom: 12px;
        .vp-title {
            font-size: 16px;
            font-weight: 600; }
        .vp-invoice-no {
            margin: 10px 0px;
            font-size: 14px;
            font-weight: 500; }
        .vp-date {
            font-size: 14px;
            font-weight: 500; } } }
