.rule-builder {
    background-color: #FFF;
    .wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        .header {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 14px;
            border-bottom: 1px solid #D9D9D9;
            .error {
                height: 32px;
                border-radius: 4px;
                border: 0.75px solid rgba(230, 71, 68, 0.30);
                background: rgba(230, 71, 68, 0.10);
                box-shadow: 0px 0px 4px 0px #F8C1BD;
                color: #E64744;
                font-size: 13px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 10px; }
            img {
                margin-right: 6px; }
            span {
                padding-top: 2px; }
            .arrow {
                font-size: 22px;
                cursor: pointer; }
            .title {
                margin-left: 14px;
                color: #000;
                font-size: 18px;
                font-weight: 600;
                margin-top: 4px; } }
        .body {
            flex-grow: 1;
            padding: 14px 0px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            .action {
                display: flex;
                margin: 18px 0px 0px 0px;
                .add-condition {
                    display: inline-flex;
                    padding: 13px 18px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #FFF;
                    font-size: 13px;
                    font-weight: 500;
                    border-radius: 4px;
                    background: #000;
                    cursor: pointer;
                    margin-right: 10px; }
                .add-group {
                    display: inline-flex;
                    padding: 13px 18px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    gap: 10px;
                    color: #000;
                    font-size: 13px;
                    font-weight: 500;
                    border-radius: 4px;
                    border: 1px solid #B2B2B2;
                    background: #FFF; } }
            .conditions {
                overflow-y: auto;
                width: 100%;
                .when {
                    display: inline-flex;
                    padding: 9px 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 6px;
                    border: 0.75px solid #21C082;
                    background: #E5FFFA;
                    margin-bottom: 6px;
                    width: fit-content; }
                .glass {
                    background: #FF0000;
                    // background: rgba(32, 31, 31, 0.07)
                    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
                    // backdrop-filter: blur(4.8px)
                    // -webkit-backdrop-filter: blur(4.8px)
 }                    // border: 1px solid rgba(32, 31, 31, 0.09)
                .item {
                    padding: 10px;
                    border-radius: 10px;
                    border: 1px solid #E1E1E1;
                    background: #FBFBFC;
                    box-shadow: 0px 2px 8px 0px rgba(233, 234, 244, 0.60);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    .delete {
                        margin-right: 10px;
                        margin-top: 8px;
                        cursor: pointer;
                        position: relative;
                        img {
                            height: 16px; } }
                    .drag {
                        margin-right: 10px;
                        // margin-top: 8px
                        cursor: pointer;
                        img {
                            height: 16px; } }
                    .condition-type {
                        width: 200px;
                        margin-right: 9px; } }
                .condition-group {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid #E1E1E1;
                    background: rgba(239, 240, 244, 0.70);
                    box-shadow: 0px 2px 8px 0px rgba(233, 234, 244, 0.60);
                    padding: 10px 10px 0px 10px;
                    position: relative;
                    .add-condition-inside {
                        color: #21C082;
                        font-size: 13px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        cursor: pointer; } }
                .condition-operator {
                    line-height: 0;
                    display: flex;
                    align-items: center; }
                .operators {
                    width: 64px;
                    margin-right: 9px; }
                .array-selection {
                    min-width: 167px;
                    width: auto;
                    margin-right: 9px; }
                .combinator {
                    position: absolute;
                    .op, .ant-select-selector {
                        width: 100px;
                        display: inline-flex;
                        padding: 9px 10px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 6px;
                        border: 1px solid #9A7BFF;
                        background: #ECE6FF;
                        color: #9A7BFF;
                        font-size: 14px;
                        font-weight: 500; } }
                .product {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-top: 12px;
                    .criteria {
                        display: flex;
                        align-items: center;
                        .type {
                            margin-right: 10px;
                            display: flex;
                            flex-direction: column;
                            label {
                                color: #797979;
                                font-size: 11px;
                                font-weight: 500;
                                margin-bottom: 6px; } } } } } } } }
