.filter {
    flex-grow: 1;
    min-height: 40px;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 0px 10px {
 }        // margin: 0px 10px
    .query {
        width: 100%;
        background: #ffffff;
        display: flex;
        padding: 0px 2px;
        height: 38px;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        .filled {
            margin: 0px 2px;
            font-size: 12px;
            letter-spacing: 0px;
            color: #ffffff;
            background: #000000 0% 0% no-repeat padding-box;
            border-radius: 17px;
            height: 26px;
            padding: 0px 10px;
            display: flex;
            align-items: center;
            .name {
                white-space: nowrap; }
            .value {
                white-space: nowrap;
                padding-top: 0px !important;
                padding-left: 0px !important;
                margin-left: 4px; }
            .remove {
                margin-left: 4px;
                cursor: pointer; } }
        .not-filled {
            font-size: 12px;
            letter-spacing: 0px;
            color: #000000;
            padding: 0px 10px;
            display: flex;
            align-items: center;
            .name {
                white-space: nowrap;
 }                // padding-top: 6px
            .value {
                // padding-top: 6px
                padding-left: 4px;
                font-weight: 600; }
            input, .ant-input:focus, .ant-input-focused {
                font-size: 14px;
                width: auto;
                border: 1px solid #2626261f;
                box-shadow: 0px 0px 0px 0px #ffffff;
 }                // padding-top: 7px
            input {
                @media screen and (min-width: 900px) and (max-width: 1400px) {
                    height: 30px;
                    border: 1px solid #2626261f;
                    margin-left: 4px; }
                @media screen and (min-width: 1400px) {
                    height: 30px;
                    border: 1px solid #2626261f;
                    margin-left: 4px; } } } }
    .conditions {
        margin-top: 30px;
        position: absolute;
        background: #F8FBFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 8px #00000034;
        border: 1px solid #CFE0EB;
        z-index: 1;
        width: 226px;
        border-radius: 4px;
        .header {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-bottom: 1px solid #CFE0EB;
            font-size: 14px;
            letter-spacing: 0px;
            color: #697B95;
            border-radius: 4px 4px 0px 0px;
            padding: 10px 10px;
            text-align: left;
            font-weight: 600; }
        .condition {
            text-align: left;
            max-height: 200px;
            overflow-y: auto;
            font-size: 14px;
            letter-spacing: 0px;
            color: #071E3D;
            .item {
                padding: 12px;
                cursor: pointer; }
            .item:hover {
                background: #0000000a; } } } }
