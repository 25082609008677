.blogs {
    font-size: 15px;
    letter-spacing: 0px;
    position: relative;
    .new-blog {
        background: #C4C4C43E;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 9999;
        .container {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            .box {
                border-radius: 16px;
                width: 582px;
                min-height: 232px;
                background: rgb(255, 255, 255);
                box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
                .title {
                    font-size: 18px;
                    font-weight: 900;
                    letter-spacing: 0px;
                    color: #010812;
                    text-transform: capitalize;
                    margin: 18px 24px; }
                .content {
                    font-size: 16px;
                    letter-spacing: 0px;
                    line-height: 24px;
                    color: #000;
                    background: transparent;
                    box-shadow: none;
                    padding: 24px;
                    min-height: 104px;
                    border-bottom-color: #EBEBEB;
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                    border-top-color: #EBEBEB;
                    border-top-style: solid;
                    border-top-width: 1px;
                    margin-top: 0px;
                    .input-validation {
                        width: 100%; }
                    .title-input {
                        width: 100%; } }
                .action {
                    justify-content: flex-end;
                    display: flex;
                    margin-top: 18px;
                    margin-bottom: 18px;
                    margin-right: 10px;
                    .create {
                        background-color: rgb(0, 0, 0);
                        color: rgb(255, 255, 255);
                        box-shadow: none;
                        border-radius: 8px;
                        font-size: 14px;
                        letter-spacing: -0.38px;
                        text-transform: capitalize;
                        border: 1px solid #000;
                        font-weight: bold;
                        width: auto; }
                    .cancel {
                        background-color: rgb(255, 255, 255);
                        color: rgb(0, 0, 0);
                        box-shadow: none;
                        border-radius: 8px;
                        width: auto;
                        border: none;
                        font-size: 14px;
                        letter-spacing: -0.38px;
                        text-transform: capitalize;
                        font-weight: bold; } } } } }
    .action-bar {
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        padding: 0px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 4px; }
        .title {
            font: 14px AvenirMedium;
            letter-spacing: 0px;
            color: #404040;
            float: left;
            margin: 10px 25px;
            line-height: 2em; }
        .search {
            border-radius: 6px;
            border: 1px solid #D8DEE6;
            background: #FFF;
            width: 353px; }
        .sp_btn {
            width: auto;
            margin-left: 12px; } }
    .content {
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .list {
            display: flex;
            flex-direction: column;
            height: 86vh;
            width: 100%;
            .item {
                width: auto;
                border-bottom: 1px solid #D8DEE6;
                margin: 0px 15px;
                padding: 15px 0px;
                height: 76px;
                .title {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600; }
                .details {
                    display: flex;
                    margin-top: 8px;
                    align-items: center;
                    .by {
                        color: #4E4E4E;
                        font-size: 13px;
                        font-weight: 400; }
                    .author {
                        color: #4E4E4E;
                        font-size: 13px;
                        font-weight: 500;
                        margin: 0px 5px; }
                    .date {
                        color: #4E4E4E;
                        font-size: 13px;
                        font-weight: 600; } }
                .blog {
                    border-radius: 50px;
                    background: #D9D9D9;
                    display: inline-flex;
                    padding: 8px 15px;
                    justify-content: center;
                    align-items: center;
                    color: #000;
                    font-size: 13px;
                    font-weight: 600; }
                .draft {
                    color: #000;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 50px;
                    background: #D9D9D9;
                    display: inline-flex;
                    padding: 8px 15px;
                    justify-content: center;
                    text-transform: uppercase;
                    align-items: center; }
                .published {
                    border-radius: 50px;
                    background: #E8FFF4;
                    display: inline-flex;
                    padding: 8px 15px;
                    justify-content: center;
                    align-items: center;
                    color: #02BA65;
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase; }
                .actions {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 100%;
                    img {
                        width: 24px;
                        height: 24px;
                        cursor: pointer; } } } } }
    .create-post {
        position: fixed;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 106;
        background: #FFF;
        top: 0;
        .container {
            position: relative;
            display: flex;
            .upload-icon {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0;
                margin: 0; }
            .sec-l {
                width: 100%;
                .draft {
                    color: #000;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 50px;
                    background: #D9D9D9;
                    display: inline-flex;
                    padding: 8px 15px;
                    justify-content: center;
                    text-transform: uppercase;
                    align-items: center; }
                .published {
                    border-radius: 50px;
                    background: #E8FFF4;
                    display: inline-flex;
                    padding: 8px 15px;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    color: #02BA65;
                    font-size: 13px;
                    font-weight: 600; }
                .header {
                    display: flex;
                    justify-content: space-between;
                    padding: 24px;
                    .title-section {
                        display: flex;
                        align-items: center;
                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                            cursor: pointer; }
                        .label {
                            color: #000;
                            font-size: 18px;
                            font-weight: 600;
                            margin-right: 27px; }
                        .draft {
                            font-size: 13px;
                            font-weight: 600;
                            border-radius: 50px;
                            background: rgba(217, 217, 217, 0.20);
                            cursor: pointer;
                            padding: 4px 15px; } }
                    .action-section {
                        display: flex;
                        align-items: center;
                        .publish {
                            border-radius: 4px;
                            background: #000;
                            color: #FFF;
                            padding: 4px 12px;
                            font-size: 13px;
                            font-weight: 500;
                            cursor: pointer; }
                        .unpublish {
                            border-radius: 4px;
                            background: #D9D9D9;
                            color: #000;
                            padding: 4px 12px;
                            font-size: 13px;
                            font-weight: 500;
                            cursor: pointer; }
                        img {
                            width: 24px;
                            height: 24px;
                            margin-left: 10px;
                            cursor: pointer; } } }
                .rich-editor {
                    display: flex;
                    justify-content: center;
                    .content {
                        display: flex;
                        flex-direction: column;
                        width: 80%;
                        padding: 0px 10px;
                        .title, .ant-input {
                            font-size: 28px;
                            font-weight: 500;
                            border: none;
                            margin-top: 45px;
                            margin-bottom: 33px; } } } }
            .sec-r {
                height: 100vh;
                transition: width 0.3s ease-in-out;
                transition: opacity 0.3s ease-in-out;
                border-left: 1px solid #D9D9D9;
                overflow: hidden;
                .details {
                    padding: 20px 14px;
                    .group {
                        margin-bottom: 20px;
                        .label {
                            color: #4E4E4E;
                            font-size: 13px;
                            font-weight: 500; }
                        .input {
                            color: #000;
                            font-size: 13px;
                            font-weight: 500; } }
                    .file-upload-field {
                        border-radius: 5px;
                        border: 1px solid #d9d9d9;
                        display: flex;
                        justify-content: space-between;
                        height: 40px;
                        padding: 0px 6px;
                        align-items: center;
                        .ant-upload-list-picture-card-container {
                            width: 100%; }
                        .ant-upload-list-picture-card .ant-upload-list-item {
                            border-radius: 0px;
                            border: none;
                            padding: 0px;
                            margin: 0px;
                            display: flex;
                            a {
                                img {
                                    height: 40px; } } } } }
                .action-section {
                    padding: 20px 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .label {
                        font-size: 18px;
                        font-weight: 600; }
                    img {
                        width: 24px;
                        height: 24px;
                        margin-left: 10px;
                        cursor: pointer; } } } } } }
