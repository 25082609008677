.theme {
    .web_view {
        display: flex;
        .setting {
            width: 30%;
            background: #f5f5f5;
            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 14px;
                .title {
                    font-size: 14px;
                    font-weight: bold; }
                img {
                    cursor: pointer;
                    transition: transform .7s ease-in-out; } }
            .contents {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 14px;
                .group {
                    margin-top: 14px;
                    button {
                        font-size: 14px;
                        text-align: left;
                        text-transform: uppercase;
                        padding: 0px 14px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between; }
                    label {
                        text-align: left;
                        font: 14px AvenirMedium;
                        letter-spacing: 0px;
                        color: #A8A8A8;
                        margin-bottom: 10px; }
                    .panel {
                        padding: 10px 10px 14px 10px;
                        background: #FFF;
                        .color_palettes {
                            display: flex;
                            align-items: center;
                            height: 46px;
                            button {
                                margin: 7px 7px 7px 0px; }
                            input, .ant-input-affix-wrapper {
                                overflow: hidden;
                                width: 88px;
                                height: 36px;
                                display: flex;
                                align-items: center; } } } } } }
        .preview {
            flex-grow: 1;
            height: 100vh;
            overflow: auto;
            iframe {
                border: none;
                width: 100%;
                height: 100vh; } } }
    .mobile_view {
        display: flex;
        .setting {
            width: 30%;
            background: #f5f5f5;
            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 14px;
                .title {
                    font-size: 14px;
                    font-weight: bold; }
                img {
                    cursor: pointer;
                    transition: transform .7s ease-in-out; } }
            .contents {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 14px;
                .group {
                    margin-top: 14px;
                    button {
                        font-size: 14px;
                        text-align: left;
                        text-transform: uppercase;
                        padding: 0px 14px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between; }
                    label {
                        text-align: left;
                        font: 14px AvenirMedium;
                        letter-spacing: 0px;
                        color: #A8A8A8;
                        margin-bottom: 10px; }
                    .panel {
                        padding: 10px 10px 14px 10px;
                        background: #FFF;
                        .color_palettes {
                            display: flex;
                            align-items: center;
                            height: 46px;
                            button {
                                margin: 7px 7px 7px 0px; }
                            input, .ant-input-affix-wrapper {
                                overflow: hidden;
                                width: 88px;
                                height: 36px;
                                display: flex;
                                align-items: center; } } } } } }
        .preview {
            flex-grow: 1;
            height: 100vh;
            overflow: auto;
            display: flex;
            justify-content: space-evenly;
            padding-top: 20px;
            // align-items: center
            iframe {
                border: none;
                width: 100%;
                height: 84vh; } } } }
