.navigation {
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .head {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font: normal normal 900 18px/25px AvenirMedium;
        letter-spacing: 0px;
        color: #000000; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .title {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                float: left;
                margin: 10px 25px;
                line-height: 2em; }
            .multi-select {
                width: 240px; }
            .btn {
                // border-radius: 5px
                // height: 30px
                // font-size: 12px
                // width: 180px
                margin: 0px 10px;
                // color: #fff
                // background: #000000 0% 0% no-repeat padding-box
                // padding: 0px
                // font-weight: 500
                // letter-spacing: -0.032em
                // box-shadow: 0px 0px 12px #96a9c340
                // border: 0px
                // font-family: 'AvenirMedium'
 } }                // line-height: 2rem
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                .ant-col {
                    padding-right: 20px; } }
            .filters {
                margin-top: 7px;
                margin-left: 30px;
                margin-bottom: 15px;
                margin-right: 30px;
                .ant-input-suffix {
                    @media screen and (min-width: 900px) and (max-width: 1400px) {
                        line-height: 1; }
                    @media screen and (min-width: 1400px) {
                        line-height: 2; }
                    img {
                        @media screen and (min-width: 900px) and (max-width: 1400px) {
                            width: 15px;
                            height: 20px; }
                        @media screen and (min-width: 1400px) {
                            width: 20px;
                            height: 24px; } } }
                .ant-col {
                    padding-right: 20px; }
                // .ant-input-affix-wrapper
                //     background: #FFFFFF 0% 0% no-repeat padding-box
                //     border: 1px solid #ccc
                //     border-radius: 5px
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                .item {
                    padding: 0px 30px;
                    display: flex;
                    align-items: center;
                    height: 56px;
                    border-top: 1px solid #f1f1f1;
                    .ant-col {
                        // padding: 10px 0px
                        opacity: 1;
                        padding-right: 20px; }
                    .ant-col:hover {
                        opacity: 0.6;
                        cursor: pointer; }
                    .description {
                        height: 48px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical; }
                    .categories {
                        padding: 0px;
                        .ant-badge {
                            margin: 0px 4px; }
                        .ant-badge-not-a-wrapper .ant-badge-count {
                            background: #DBE2EA6C;
                            color: #000;
                            border-radius: 4px;
                            height: 26px;
                            padding: 3px 10px; } }
                    .instock {
                        color: #07C180; }
                    .outstock {
                        color: #ED1536; } } } }
        .active {
            width: 2px;
            height: 100%;
            background-color: #46b275;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; }
        .inactive {
            width: 2px;
            height: 100%;
            background-color: #a8abbd;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; } } }
.menu-save {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-footer {
        height: 66px;
        border-top: 1px solid #E5E5E5;
        display: flex;
        justify-content: flex-end;
        align-items: center; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        background: #f5f5f5;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 16px 0px;
        align-items: flex-start;
        .row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start; }
        .icon {
            margin-left: 4px;
            width: 300px;
            height: 200px;
            background: #fff;
            border-radius: 4px;
            padding: 15px;
            .title {
                font-size: 15px;
                font-weight: 600; } }
        .detail {
            border-radius: 8px;
            width: 60vw;
            // height: 650px
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            padding: 16px;
            .items {
                position: relative;
                .overlay {
                    top: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #00000000; } }
            .title {
                font-size: 15px;
                font-weight: 600;
                line-height: 20.49px;
                text-align: left;
                margin-bottom: 12px; }
            .desc {
                font-size: 13px;
                font-weight: 500;
                line-height: 17.76px;
                text-align: left;
                color: #909398; }
            .menu-title {
                font-size: 15px;
                font-weight: 500;
                text-align: left;
                color: #909398;
                margin: 15px 0px 6px 0px; }
            .menu-tile-value {
                color: #000;
                font-size: 18px;
                font-weight: 600; }
            input {
                border: 1px solid #D9D9D9;
                border-radius: 6px;
                padding: 12px; }
            .divider {
                width: 100%;
                border: 1px dashed #D9D9D9;
                margin: 12px 0px 24px 0px; }
            .add-menu {
                font-size: 13px;
                font-weight: 600;
                line-height: 17.76px;
                text-align: left;
                color: #21C082;
                margin-top: 22px;
                cursor: pointer;
                box-shadow: none;
                border: none; }
            .has-child + .menu-item {
                background-color: lightgreen; }
            // .child-menu:not(.has-child  +.menu-item)
            //  // background-color: lightgreen
            .menu-item {
                background: #FFF;
                min-height: 44px;
                width: 100%;
                border-radius: 10px;
                padding: 0px 13px;
                display: flex;
                align-items: center;
                border: 1px solid #E1E1E1;
                justify-content: space-between;
                margin: 12px 0px;
                .info {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    border-right: 1px solid #E1E1E1;
                    .drag {
                        cursor: pointer;
                        color: #000;
                        margin-right: 12px; }
                    .m-name {
                        font-size: 13px;
                        font-weight: 600;
                        text-align: left;
                        color: #121212; }
                    .child-menu {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        padding: 0px 12px;
 } }                        // min-height: 44px
                .action {
                    justify-content: space-between;
                    display: flex;
                    align-items: center;
                    padding-left: 12px;
                    width: 70px;
                    height: 100%;
                    img {
                        cursor: pointer; } } } } } }
.add-menu-modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    border-radius: 0px;
    height: 100vh !important;
    background-color: #FFF;
    .ant-modal-content {
        box-shadow: none;
        position: relative;
        height: 100%;
        .ant-modal-body {
            height: 100%;
            .content {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
                .header {
                    height: 60px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 14px;
                    border-bottom: 1px solid #D9D9D9;
                    .error {
                        width: 265px;
                        height: 32px;
                        border-radius: 4px;
                        border: 0.75px solid rgba(230, 71, 68, 0.30);
                        background: rgba(230, 71, 68, 0.10);
                        box-shadow: 0px 0px 4px 0px #F8C1BD;
                        color: #E64744;
                        font-size: 13px;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            margin-right: 6px; }
                        span {
                            padding-top: 2px; } }
                    .arrow {
                        font-size: 22px;
                        cursor: pointer;
                        margin-right: 9px; }
                    .title {
                        color: #000;
                        font-size: 18px;
                        font-weight: 600;
                        margin-top: 4px; } }
                .body {
                    flex-grow: 1;
                    padding: 14px 14px 0px 14px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: flex-start;
                    .link-options {
                        margin-top: 8px;
                        border-radius: 4px;
                        border: 1px solid #D8DEE6;
                        background: #FFF;
                        box-shadow: 0px 0px 8px 0px rgba(26, 26, 26, 0.14);
                        padding: 15px 15px 0px 15px;
                        .option-select {
                            color: #121212;
                            font-weight: 600; }
                        .option {
                            padding: 0px 0px 15px 0px;
                            color: #707070;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            cursor: pointer;
                            &:hover {
                                color: #121212;
                                font-weight: 600; } } }
                    .label {
                        color: #4E4E4E;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 6px; }
                    textarea {
                        border-radius: 6px;
                        border: 1px solid #D9D9D9;
                        background: #FFF;
                        flex-shrink: 0;
                        width: 100% !important;
                        padding: 12px;
                        resize: none;
                        outline: none !important; }
                    textarea::focus-visible {
                        outline: none !important; }
                    .input {
                        border-radius: 6px;
                        border: 1px solid #D9D9D9;
                        background: #FFF;
                        height: 44px;
                        flex-shrink: 0;
                        width: 100%;
                        padding: 0px 12px; }
                    .action {
                        display: flex;
                        margin: 18px 0px 0px 0px; } } } } } }
.add-menu {
    background: #FFF;
    width: 470px;
    top: 30%;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border-radius: 14px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
    padding: 0px;
    .ant-modal-content {
        box-shadow: none;
        position: relative;
        height: 100%;
        .ant-modal-body {
            padding: 12px 10px;
            height: 100%;
            .title {
                color: #4E4E4E;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal; }
            input {
                border-radius: 6px;
                border: 1px solid #D9D9D9;
                background: #FFF;
                height: 36px;
                flex-shrink: 0;
                padding: 0px 10px; } }
        .ant-modal-header {
            border-bottom: 1px solid #D9D9D9;
            padding: 14px 10px; } }
    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px 10px;
        .create {
            width: auto;
            margin-left: 12px;
            font-size: 13px;
            font-style: normal;
            font-weight: 500; } } }
