.input-validation {
    .block {
        .error {
            background: #FF0000 0% 0% no-repeat padding-box;
            border: 1px solid #FF0000;
            border-radius: 4px;
            font: 12px AvenirMedium;
            letter-spacing: 0px;
            color: #FFFFFF; }
        .warning {
            background: #FF8100 0% 0% no-repeat padding-box;
            border: 1px solid #FF8100;
            border-radius: 4px;
            font: 12px AvenirMedium;
            letter-spacing: 0px;
            color: #FFFFFF; }
        .right {
            background: #4caf50 0% 0% no-repeat padding-box;
            border: 1px solid #4caf50;
            border-radius: 4px;
            font: 12px AvenirMedium;
            letter-spacing: 0px;
            color: #FFFFFF; }
        .message {
            padding-left: 12px;
            padding-top: 4px; } } }
