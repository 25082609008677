.media-assets {
    .image-container {
        display: flex;
        flex-wrap: wrap;
        .upload-icon {
            width: 102px;
            height: 102px;
            margin-inline-end: 8px;
            margin-bottom: 8px;
            text-align: center;
            vertical-align: top;
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px dashed #d9d9d9;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: border-color 0.3s;
            img {
                width: 24px;
                height: 24px; } }
        .image-pre {
            position: relative;
            margin: 0 5px;
            width: 102px;
            overflow: hidden;
            height: 102px;
            margin-inline-end: 8px;
            margin-bottom: 8px;
            text-align: center;
            vertical-align: top;
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px dashed #d9d9d9;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: border-color 0.3s;
            img {
                object-fit: contain;
                height: 100%; }
            img:hover + .overlay, .overlay:hover {
                display: flex; }
            .overlay {
                display: none;
                border-radius: 6px;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.5);
                span {
                    font-size: 14px;
                    color: #fff;
                    font-weight: 600;
                    padding: 4px;
                    border-radius: 5px;
                    margin: 0 5px;
                    cursor: pointer; } } } } }

.media-assets-upload {
    // top: 0
    // left: 0
    // padding: 0px
    // margin: 0px
    // height: 100vh
    // width: 100%
    // width: calc(80vw - 200px)
    .file-preview {
        position: relative;
        margin: 0 5px;
        width: 160px;
        height: 160px;
        margin-inline-end: 8px;
        margin-bottom: 8px;
        text-align: center;
        vertical-align: top;
        background-color: rgba(0, 0, 0, 0.02);
        border: 1px dashed #d9d9d9;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border-color 0.3s;
        img, video, .audio {
            object-fit: contain;
            width: 100%;
            height: 100%; }
        img:hover + .overlay, .overlay:hover {
            display: flex; }
        video:hover + .overlay, .overlay:hover {
            display: flex; }
        audio:hover + .overlay, .overlay:hover {
            display: flex; }
        .overlay {
            display: none;
            border-radius: 6px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.5);
            span {
                font-size: 14px;
                color: #fff;
                font-weight: 600;
                padding: 4px;
                border-radius: 5px;
                margin: 0 5px;
                cursor: pointer; } } }
    .ant-tabs-content-holder {
        height: calc(80vh - 200px); }
    .ant-tabs-tab-next, .ant-tabs-tab-prev {
        display: none; }
    .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: 16px; }
    .image-transform {
        border: 1px solid #777;
        .ant-select-selection-item, .ant-select-arrow {
            color: #121212; } }
    .upload {
        background: #34E5C1 0% 0% no-repeat padding-box;
        border-radius: 3px;
        font-size: 13px;
        padding: 0px 16px;
        letter-spacing: 0px;
        color: #ffffff;
        border: none;
        width: 100px;
        &.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
            text-decoration: none;
            background: #34E5C1;
            color: #ffffff; } }
    .cancel {
        width: 100px;
        border: none;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 3px;
        font-size: 13px;
        letter-spacing: 0px;
        color: #697B95;
        box-shadow: none;
        &.ant-btn:hover, .ant-btn:focus, .ant-btn:active, .ant-btn.active {
            text-decoration: none;
            background: #ffffff;
            color: #697B95; } }
    .ant-tabs-nav-wrap,  .ant-tabs-nav .ant-tabs-tab:hover, {
        font-size: 14px;
        letter-spacing: 0px;
        color: #697B95;
        cursor: pointer;
        .ant-tabs-nav .ant-tabs-tab-active {
            color: #000000; }
        .ant-tabs-ink-bar {
            height: 1px;
            background: #000000 0% 0% no-repeat padding-box;
            sborder-radius: 11px; } }
    .ant-tabs-content {
        margin: 10px 0px;
        .ant-tabs-tabpane {
            .preview-image {
                object-fit: contain;
                width: 60px;
                height: 60px;
                background: #CFE0EB 0% 0% no-repeat padding-box;
                border-radius: 4px; }
            .ant-upload.ant-upload-drag {
                border: none;
                background: #fff; }
            .ant-upload-text, .ant-upload-hint {
                font-size: 14px;
                letter-spacing: 0px;
                color: #CCD0DD; }
            .select-icn {
                width: 150px;
                height: 30px;
                background: #ECEEF2 0% 0% no-repeat padding-box;
                border: 1px solid #E5E5E5;
                border-radius: 3px;
                font-size: 13px;
                letter-spacing: 0px;
                color: #697B95;
                padding: 6px 16px; }
            .search {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #ADB7C3;
                border-radius: 4px;
                .ant-input-suffix {
                    img {
                        width: 22px;
                        height: 22px;
                        color: #ADB7C3;
                        position: absolute;
                        right: 6px;
                        top: 7px; } } }
            .pics {
                display: flex;
                width: 100%;
                overflow-y: auto;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                max-height: calc(75vh - 200px);
                div {
                    border-width: 2.5px;
                    border-style: dotted;
                    border-color: #00000000;
                    background: #f4f4f4 0% 0% no-repeat padding-box;
                    width: 160px;
                    height: 160px;
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-top: 12px;
                    overflow: hidden;
                    padding: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img {
                        width: 150px;
                        height: 150px;
                        border-radius: 4px;
                        object-fit: contain;
                        overflow: hidden; }
                    video {
                        width: 150px;
                        height: 150px;
                        border-radius: 4px;
                        overflow: hidden; }
                    .audio {
                        width: 150px;
                        height: 150px;
                        border-radius: 4px;
                        background: #000000 0% 0% no-repeat padding-box;
                        img {
                            width: 40px;
                            height: 40px;
                            color: #ADB7C3;
                            position: absolute;
                            right: 6px;
                            top: 7px; } } } } } }
    .ant-modal-body {
        padding: 0px 20px;
        border-bottom: 1px solid #d9d9d9; }
    .ant-modal-footer {
        margin-top: 0px; }
    .ant-modal-header {
        background: #000000 0% 0% no-repeat padding-box;
        color: #ffffff;
        height: 45px;
        padding: 10px 18px;
        .ant-modal-title {
            font-size: 18px;
            letter-spacing: 0px;
            color: #FFFFFF;
            text-transform: capitalize; } }
    .ant-upload-drag-icon {
        display: flex;
        align-items: center;
        justify-content: center; }
    .ant-modal-close {
        top: 10px;
        .ant-modal-close-x {
            color: #FFFFFF; } } }
