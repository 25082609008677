.delivery-slots {
    height: 100%;
    .action-bar {
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        padding: 15px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .header-title {
        font: 14px AvenirMedium;
        letter-spacing: 0px;
        color: #404040;
        float: left;
        margin: 10px 25px;
        line-height: 2em; }
    .contents {
        flex-wrap: wrap;
        display: flex;
        .card {
            width: 296px;
            height: 350px;
            background: #FFFFFF;
            border: 1px solid #EFEFEF;
            border-radius: 10px;
            padding: 20px;
            margin: 15px 0px 0px 28px;
            cursor: pointer;
            .title {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .name {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0px;
                color: #000000; }
            .time {
                margin-top: 24px;
                font-size: 14px;
                letter-spacing: 0px;
                color: #808080;
                width: 100%; }
            .time-value {
                height: 34px;
                background: #DBE2EA6C;
                border-radius: 6px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                margin-top: 9px; }
            .time-desc {
                font-size: 12px;
                letter-spacing: 0px;
                color: #4E4E4E;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap; }
            .time-content {
                color: #188FFC;
                margin: 0px 2px;
                font-weight: bold; }
            .slot-state {
                font-size: 14px;
                padding: 5px 0px 0px 0px;
                color: #4E4E4E;
                margin-right: 14px; }
            .not-active {
                font-size: 15px;
                letter-spacing: 0px;
                color: #FC3218;
                display: flex;
                align-items: center;
                justify-content: flex-start; }
            .active {
                font-size: 15px;
                padding: 5px 0px 0px 0px;
                color: #02BA65;
                display: flex;
                align-items: center;
                justify-content: flex-start; }
            .fixed-slot {
                height: 30px;
                background: #CFFADF79;
                border-radius: 15px;
                padding: 0px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #02BA65;
                text-transform: capitalize;
                text-wrap: nowrap; }
            .flexible-slot {
                color: #188FFC;
                padding: 0px 12px;
                height: 30px;
                background: #80B5FD34;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize;
                text-wrap: nowrap; } } } }
.delivery-slot-view {
    .minutes-picker {
        background: #FFFFFF;
        border: 1px solid #9AA3AD;
        border-radius: 4px;
        display: flex;
        align-items: center;
        .ant-select-selector {
            background: #DBE2EA41; }
        .ant-select {
            border: none; }
        .value {
            border: none !important; } }
    .time-picker {
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #9AA3AD;
        border-radius: 4px;
        display: flex;
        .time-picker-label {
            padding: 0px 10px;
            display: flex;
            align-items: center;
            width: 76px;
            border-right: 1px solid #9AA3AD;
            background: #DBE2EA6C; }
        .ant-picker {
            border: none;
            input {
                text-align: center; } } }
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        width: 100%;
        height: 100%;
        padding: 6px 40px;
        .group {
            margin: 16px 0px;
            .label {
                height: 19px;
                text-align: left;
                font-size: 16px;
                letter-spacing: 0px;
                color: #4E4E4E;
                margin-bottom: 8px; }
            .value {
                background: #FFFFFF;
                border: 1px solid #ADB7C3;
                border-radius: 4px;
                resize: none;
                width: 100%; } } } }
