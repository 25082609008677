.btheme {
    padding: 22px 16px;
    .title {
        color: #121212;
        font-size: 24px;
        font-weight: 600; }
    .subtitle {
        color: #121212;
        font-size: 24px;
        font-weight: 600; }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px;
        .search {
            border-radius: 6px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            width: 376px;
            height: 36px;
            flex-shrink: 0;
            padding: 0px 10px; } }
    .presets {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;
        .m-button, .m-button:focus-visible {
            background: transparent !important;
            padding: unset !important;
            margin: unset !important;
            position: relative;
            outline: none; }
        .preset {
            border: 1px solid rgb(217, 217, 217);
            background: #fff;
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: relative;
            .p-color {
                width: 100%;
                height: 100%;
                border-radius: 50%; } } }
    .card:hover {
        box-shadow: 0px 3px 24px 0px #00000014; }
    .card {
        width: 375px;
        height: 504px;
        border-radius: 20px;
        border: 1px solid #d9d9d9;
        box-shadow: none;
        .preview {
            height: 380px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 20px 20px 0px 0px;
                object-fit: fill; } }
        .details {
            width: 375px;
            height: 124px;
            padding: 22px;
            border-radius: 0px 0px 20px 20px;
            border-top: 1px solid #D9D9D9;
            .theme-title {
                font-size: 20px;
                font-weight: 600; }
            .action {
                margin-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .try-this, .config-settings {
                    background: #DEDEDE;
                    color: #121212;
                    box-shadow: none;
                    border: none;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: unset; }
                .try-this:hover {
                    background: #202020;
                    color: #fff;
                    box-shadow: none;
                    border: none;
                    font-size: 14px;
                    font-weight: 600; }
                .price {
                    font-size: 20px;
                    font-weight: 600; }
                .installed {
                    cursor: default;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 79px;
                    height: 36px;
                    padding: 13px 12px 13px 12px;
                    border-radius: 6px;
                    background: #FFEFC0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #202020; }
                .active {
                    font-size: 14px;
                    font-weight: 600;
                    color: #21C082; } } } }
    .discover::-webkit-scrollbar {
        display: none; }
    .discover {
        overflow-y: auto;
        height: 86vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 45px;
        .header {
            display: flex;
            align-items: center;
            .desc {
                width: 552px;
                color: #121212;
                .title {
                    font-size: 36px;
                    font-weight: 600; }
                .subtitle {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 25px; } }
            .banner {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                img {
                    width: 90%; } } }
        .theme-search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 65px;
            margin-bottom: 20px;
            .title {
                font-size: 24px;
                font-weight: 600;
                color: #121212; }
            .search {
                border-radius: 6px;
                border: 1px solid #D9D9D9;
                background: #FFF;
                width: 376px;
                height: 36px;
                padding: 0px 10px; } }
        .list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 25px;
            gap: 42px; } }
    .installed::-webkit-scrollbar {
        display: none; }
    .installed {
        padding: 45px;
        overflow-y: auto;
        height: 86vh;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 25px;
            gap: 22px;
            .card:hover {
                box-shadow: 0px 3px 24px 0px #00000014; } } }
    .configurator {
        display: flex;
        background: #F5F5F5;
        .theme-apply-preset {
            box-shadow: 0px 0px 24px -4px #1018283D;
            width: 400px;
            height: 494px;
            border-radius: 12px;
            padding: 14px;
            background: #fff;
            position: absolute;
            top: 40px;
            .popover-arrow {
                position: absolute;
                top: -26px;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid #ffffff;
                width: 0;
                height: 0;
                -webkit-filter: drop-shadow(0px 0px 24px -4px #1018283D); }
            .container {
                position: relative;
                .theme-img {
                    width: 372px;
                    height: 361px; }
                .action {
                    width: 400px;
                    height: 114px;
                    padding: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .title {
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 14px; }
                    .apply {
                        cursor: pointer;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                        background: #202020;
                        width: 250px;
                        height: 44px;
                        border-radius: 8px;
                        box-shadow: 0px 1px 2px 0px #1018280D; } } } }
        .divider {
            margin: 0px 13px;
            width: 1px;
            height: 23px;
            background: #D9D9D9; }
        .names {
            padding: 8px;
            width: 225px;
            height: calc(100vh - 72px);
            overflow-y: auto;
            flex-shrink: 0;
            border-right: 1px solid #D9D9D9;
            background: #FFF;
            color: #121212;
            font-size: 13px;
            font-weight: 500;
            .back-option {
                display: flex;
                align-items: center;
                font-weight: bold;
                cursor: pointer;
                height: 54px;
                position: fixed;
                top: 72px;
                background: #fff;
                width: 212px; }
            .item {
                cursor: pointer;
                height: 32px;
                display: flex;
                align-items: center;
                padding: 0px 6px;
                margin-bottom: 8px; }
            .selected {
                cursor: default;
                color: #121212;
                font-size: 13px;
                font-weight: 600;
                padding: 20px 6px;
                border-radius: 4px;
                background: rgba(203, 203, 203, 0.20); } }
        .detail {
            height: 90vh;
            width: 100%;
            // overflow-y: auto
            .header {
                height: 54px;
                padding: 0px 14px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 8px 0px;
                .action {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%; }
                .search {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 6px;
                    border: 1px solid #D8DEE6;
                    background: #FFF;
                    border-radius: 6px;
                    border: 1px solid #D8DEE6;
                    background: #FFF;
                    width: 353px;
                    height: 40px;
                    padding: 0px 10px;
                    img {
                        width: 18px;
                        height: 18px; }
                    input {
                        height: 38px;
                        border: none; } }
                button {
                    cursor: pointer;
                    margin-left: 10px;
                    display: inline-flex;
                    height: 40px;
                    padding: 10px 22px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                    border: 0px solid #D8DEE6;
                    background: #121212;
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 500; } }
            .section {
                margin: 0px 14px;
                padding: 16px;
                border-radius: 8px;
                border: 1px solid #D9D9D9;
                background: #FFF;
                box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12);
                .row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    .col-val {
                        input {
                            width: 100%;
                            border-radius: 8px;
                            border: 1px solid #D9D9D9;
                            background: #FFF;
                            padding: 0px 10px; } }
                    .col-key, .col-val {
                        width: 50%;
                        .title {
                            text-transform: capitalize;
                            color: #000;
                            font-size: 15px;
                            font-weight: 500; }
                        .desc {
                            color: #707070;
                            font-size: 13px;
                            font-weight: 500; } } }
                .row:last-child {
                    margin-bottom: 0px; } } } } }
