.signin {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: url(../../../assets/images/doodle.svg);
    .header {
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 35px;
        .left {
            display: flex;
            align-items: center;
            .logo {
                display: flex;
                align-items: center;
                img {
                    width: 68px;
                    margin-right: 37; }
                label {
                    font-size: 30px;
                    font-weight: bold;
                    padding-top: 10px; } }
            .create_store {
                width: 178px;
                height: 36px;
                background: #FFFFFF;
                border: 1px solid #000000;
                border-radius: 22px;
                margin-left: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                cursor: pointer; } }
        .right {
            .pp_tc {
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0px;
                color: #000000;
                display: flex;
                cursor: pointer;
                .pp {
                    margin-right: 32px; } } } }
    .content {
        flex-grow: 1;
        margin-top: 87px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .motto {
            font-size: 50px;
            font-weight: 900;
            letter-spacing: 0px;
            color: #000000; }
        .desc {
            font-weight: 300;
            font-size: 20px;
            line-height: 2;
            width: 440px;
            text-align: center; }
        .oauth {
            margin-top: 56px;
            button {
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFF;
                font-size: 16px;
                width: 313px;
                height: 61px;
                background: #1A1A1A;
                border-radius: 10px;
                margin-bottom: 42px;
                cursor: pointer;
                label {
                    cursor: pointer;
                    padding-top: 4px; } }
            .email-login {
                border-radius: 4px;
                border: 1px solid #000;
                background: #FFF;
                box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
                color: #21C082;
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                cursor: pointer; }
            .google {
                cursor: pointer;
                img {
                    width: 28px;
                    margin-right: 16px; } }
            .fb {
                cursor: pointer;
                i {
                    color: #FFF;
                    margin-right: 20px;
                    margin-left: 10px;
                    background: #317FE5;
                    padding: 6px 8px 0px 8px;
                    font-size: 20px;
                    border-radius: 2px; } } } }

    .footer {
        height: 50px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center; } }
.email {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: url(../../../assets/images/doodle.svg);
    .header {
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 35px;
        .left {
            display: flex;
            align-items: center;
            .logo {
                display: flex;
                align-items: center;
                img {
                    width: 68px;
                    margin-right: 37; }
                label {
                    font-size: 30px;
                    font-weight: bold;
                    padding-top: 10px; } }
            .create_store {
                width: 178px;
                height: 36px;
                background: #FFFFFF;
                border: 1px solid #000000;
                border-radius: 22px;
                margin-left: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                cursor: pointer; } }
        .right {
            .pp_tc {
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0px;
                color: #000000;
                display: flex;
                cursor: pointer;
                .pp {
                    margin-right: 32px; } } } }
    .content {
        flex-grow: 1;
        margin-top: 87px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .signin {
            width: 460px;
            .header {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                padding: 0px;
                margin: 0px;
                height: auto;
                margin-bottom: 30px;
                .title {
                    color: #000;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: normal;
                    margin-right: 6px; }
                .code {
                    color: #21C082;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: normal;
                    margin-right: 4px; } }
            .title {
                color: #5A5A5A;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                line-height: normal;
                .title-plus {
                    color: #000;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: normal; }
                div {
                    flex: 1; } }
            .email-or-phone {
                border-radius: 4px;
                border: 1px solid #000;
                background: #FFF; }
            .register {
                color: #21C082;
                font-size: 20px;
                font-style: normal;
                font-weight: 500; }
            .new {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 500; }
            .email {
                margin-top: 29px;
                border-radius: 4px;
                border: 1px solid #000;
                background: #FFF;
                height: 48px;
                padding: 0px 10px; }
            .error {
                color: #E00F0F;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                .message {
                    font-size: 12px;
                    color: #fff; } }
            .otp-action {
                display: flex;
                justify-self: flex-end;
                .timer {
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal; }
                .resend-disable {
                    color: #A7A7A7;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: normal;
                    text-decoration-line: underline; }
                .resend-enable {
                    color: #4A9CFD;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 600;
                    line-height: normal;
                    text-decoration-line: underline; } }
            .send-otp {
                margin-top: 32px;
                border-radius: 4px;
                border: 1px solid #000;
                background: #000;
                box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
                height: 48px;
                color: #FFF;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                line-height: normal; }
            .new-reg {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                padding: 0px;
                margin-top: 40px;
                .new {
                    color: #000;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: normal;
                    margin-right: 6px; }
                .reg {
                    color: #21C082;
                    font-size: 20px;
                    font-weight: 500;
                    cursor: pointer;
                    line-height: normal; } } } } }
