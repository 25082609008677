.notification {
    .action-bar {
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 61px;
        .title {
            color: #141414;
            font-size: 14px;
            font-weight: 500; } }
    .content {
        border-radius: 4px;
        border: 1px solid #D8DEE6;
        background: #FFF;
        .title {
            color: #4E4E4E;
            font-size: 13px;
            font-weight: 500;
            margin: 0px; }
        .header {
            height: 59px;
            padding: 0px 20px;
            border-bottom: 1px solid #D8DEE6; }
        .contains {
            .item {
                cursor: pointer;
                height: 59px;
                margin: 0px 20px;
                padding: 13px 0px;
                display: flex;
                border-bottom: 1px solid #D8DEE6; } } }
    .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        .main-title {
            color: #292D32;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            margin-top: 24px; }
        .sub-title {
            color: #777;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 24px; }
        .create-notify {
            width: 152px; } } }
.notification-save {
    .ant-drawer-header {
        padding: 0px; }
    .ant-drawer-body {
        margin: 0px;
        padding: 0px;
        background: #F7F7F7;
        .content {
            display: flex;
            justify-content: center;
            padding: 30px 0px;
            .group {
                margin-right: 11px;
                .section {
                    margin-bottom: 12px;
                    padding: 15px;
                    width: 779px;
                    border-radius: 8px;
                    border: 1px solid #D9D9D9;
                    background: #FFF;
                    box-shadow: 0px 4px 16px 0px rgba(198, 198, 198, 0.12);
                    .icon:hover .action {
                        display: flex; }
                    .icon {
                        border-radius: 6px;
                        border: 1px dashed #707070;
                        background: #FFF;
                        width: 134px;
                        position: relative;
                        height: 134px;
                        margin-top: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        .action {
                            position: absolute;
                            display: none;
                            background: #3733334d;
                            justify-content: space-evenly;
                            width: 100%;
                            height: 100%;
                            align-items: center; }
                        .text {
                            color: #707070;
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: 5px; } }
                    .divider {
                        margin: 20px 0px; }
                    label {
                        color: #4E4E4E;
                        font-size: 13px;
                        font-weight: 500; }
                    input, textarea {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 13px;
                        font-weight: 500;
                        width: 100%;
                        padding: 0px 10px;
                        border-radius: 6px;
                        border: 1px solid #D9D9D9;
                        background: #FFF;
                        height: 40px; }
                    textarea:focus, textarea:active, textarea:focus-visible {
                        outline: none; }
                    textarea {
                        resize: none;
                        height: 140px;
                        padding: 10px; }
                    .ant-select-selection-search input {
                        border: none; } } } } }
    .header {
        border-bottom: 1px solid #D9D9D9;
        background: #FFF;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 17px;
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; }
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; } } }
