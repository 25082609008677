.uset_type {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        .motto {
            font-size: 50px;
            font-weight: 900;
            letter-spacing: 0px;
            color: #000000; }
        .desc {
            font-weight: 300;
            font-size: 20px;
            line-height: 2;
            text-align: center; }
        .oauth {
            margin-top: 56px;
            button {
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFF;
                font-size: 16px;
                width: 313px;
                height: 61px;
                background: #1A1A1A;
                border-radius: 10px;
                margin-bottom: 42px;
                cursor: pointer;
                label {
                    cursor: pointer;
                    padding-top: 4px; } }
            .google {
                cursor: pointer;
                img {
                    width: 28px;
                    margin-right: 16px; } }
            .fb {
                cursor: pointer;
                i {
                    color: #FFF;
                    margin-right: 20px;
                    margin-left: 10px;
                    background: #317FE5;
                    padding: 6px 8px 0px 8px;
                    font-size: 20px;
                    border-radius: 2px; } } } }

    .footer {
        height: 50px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center; } }
