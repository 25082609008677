.holidays {
    display: flex;
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .list {
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 320px; }
            .title {
                font-weight: bold;
                font-size: 16px;
                letter-spacing: 0px;
                color: #000000;
                margin-bottom: 6px; } }
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            font: 13px AvenirMedium;
            font-weight: 500;
            padding: 0px 14px;
            .header {
                letter-spacing: 0px;
                color: #4E4E4E;
                padding: 20px 0px;
                margin: 15px 0px;
                border-bottom: 1px solid #f1f1f1; }
            .contains {
                overflow-y: auto;
                .item {
                    cursor: default;
                    background: #fff;
                    color: #000000;
                    height: 55px; } } } } }
.add_holiday {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        padding: 20px 17px;
        .group {
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: flex-start; }
        .label {
            font-size: 16px;
            letter-spacing: 0px;
            color: #4E4E4E;
            margin-bottom: 8px; }
        .ant-input {
            height: 40px; }
        .ant-input:focus, .ant-input-focused {
            border: 1px solid #121212; }
        .date {
            display: flex;
            gap: 10px; } } }


