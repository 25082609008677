.color_palettes {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .title {
        font-size: 14px !important;
        font-weight: bold !important;
        letter-spacing: 0px;
        color: #121212 !important;
        width: 100%;
        margin-bottom: 10px; }
    .palettes {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .picker {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between; }
        .custom-color {
            display: flex;
            height: 28px;
            align-items: center;
            .code {
                box-shadow: 0px 3px 6px #00000029;
                border: 2px solid #FFFFFF;
                border-radius: 5px;
                overflow: hidden; } }
        .swatch {
            width: 28px;
            height: 28px;
            border-radius: 8px;
            border: 3px solid #fff;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
            cursor: pointer; }
        .popover {
            position: absolute {
 }                // top: calc(100% + 2px)
            // right: 0-23px
            margin-top: 246px;
            border-radius: 9px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
            z-index: 1; }
        .custom-layout {
            .react-colorful {
                padding: 16px;
                border-radius: 12px;
                background: #33333a;
                box-shadow: 0 6px 12px #999; }
            .react-colorful__saturation {
                margin: 15px 0;
                border-radius: 5px;
                border-bottom: none; }
            .react-colorful__hue {
                order: -1;
                height: 14px;
                border-radius: 5px; }
            .react-colorful__alpha {
                height: 14px;
                border-radius: 5px; }
            .react-colorful__hue-pointer,
            .react-colorful__alpha-pointer {
                width: 20px;
                height: 20px; } } } }
