.account {
    font-size: 15px;
    letter-spacing: 0px;
    .action-bar {
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        padding: 0px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 4px; }
        .title {
            font: 14px AvenirMedium;
            letter-spacing: 0px;
            color: #404040;
            float: left;
            margin: 10px 25px;
            line-height: 2em; } }
    .title {
        color: #000000;
        text-transform: uppercase; }
    .content {
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .card {
            cursor: pointer;
            margin: 10px 20px 10px 0px;
            width: 350px;
            height: 175px;
            background: #FFFFFF;
            border: 1px solid #CFE0EB;
            border-radius: 10px;
            padding: 4px 14px 0px 14px;
            .divider {
                border-bottom-color: #CFE0EB;
                border-bottom-style: solid;
                height: 42px;
                border-bottom-width: 1px;
                margin-bottom: 10px; }
            .row {
                display: flex;
                align-items: center;
                margin-bottom: 16px; }
            .col {
                display: flex;
                flex-direction: column; }
            .bank {
                color: #000000;
                text-transform: uppercase;
                margin-right: 13px; }
            .primary {
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                padding: 12px;
                height: 25px;
                background: #000;
                border-radius: 15px;
                color: #FFF; }
            .set-primary {
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 105px;
                height: 25px;
                border: 1px solid #E2EBF5;
                border-radius: 15px;
                color: #0076FF;
                opacity: 1;
                background: #FFF; }
            .set-primary:hover {
                background: #E2EBF5; }
            .label {
                text-transform: capitalize;
                color: #697B95;
                font-size: 12px; }
            .value {
                color: #000;
                font-size: 14px;
                padding-top: 5px;
                padding-bottom: 5px; } } }
    .payouts {
        margin: 14px 28px; }
    .accounts {
        margin: 14px; } }
.account-save {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        // box-shadow: 0px 0px 10px #96A9C340
        // border-radius: 5px
        width: 100%;
        height: 100%;
        .details {
            padding: 6px 40px;
            .basic-info {
                font: 900 22px AvenirMedium;
                letter-spacing: 0px;
                color: #fff; }
            .group {
                margin: 16px 0px;
                .label {
                    height: 19px;
                    text-align: left;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4E4E;
                    margin-bottom: 8px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    img {
                        cursor: pointer; } }
                .outlets {
                    height: 200px;
                    overflow-y: auto;
                    .item {
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #ADB7C3;
                        border-radius: 5px;
                        padding: 16px 10px;
                        .name {
                            font: 16px/22px AvenirMedium;
                            letter-spacing: -0.51px;
                            color: #071E3D; }
                        .label {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #576B83; }
                        .value {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #00BDFF;
                            border: none; }
                        .instock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #07C180; }
                        .outstock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #ED1536; } } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            padding-left: 40px;
            // .save
            //     width: 165px
            //     background: #FFF
            //     color: #000
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            // .submit
            //     width: 146px
            //     height: 40px
            //     background: #11A1FD 0% 0% no-repeat padding-box
            //     font-size: 15px
            //     letter-spacing: 0px
            //     color: #FFFFFF
            //     border-radius: 5px
            // .cancel
            //     margin-right: 40px
            //     border: none
            //     font-size: 15px
            //     letter-spacing: 0px
 } } }            //     color: #7D9EB5
