.overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .line {
        width: 100%;
        background: #FF9933;
        height: 2px; }
    .note {
        position: relative;
        height: 29px;
        width: 476px;
        text-align: center;
        color: #121212;
        display: flex;
        align-items: center;
        justify-content: center; }
    .note:before,
    .note:after {
        position: absolute;
        content: '';
        top: 0px;
        height: 100%;
        width: 55%;
        background: linear-gradient(180deg, #FF9933 0%, #FABC04 100%);
        border-left-width: 3px;
        z-index: -1; }

    .note:before {
        border-radius: 0px 0px 5px 5px;
        border-right: none;
        transform: skew(322deg);
        transform-origin: bottom left; }

    .note:after {
        border-radius: 0px 5px 5px 5px;
        border-right: none;
        transform: skew(40deg);
        transform-origin: bottom left; }

    .due {
        font-size: 13px;
        font-weight: 500; }
    .pay {
        font-size: 13px;
        font-weight: 600;
        margin-left: 4px;
        cursor: pointer;
        text-decoration: underline;
        z-index: 10; } }
.billing {
    height: 100%; }
.plans {
    display: flex;
    justify-content: center;
    align-items: center;
    .row {
        display: flex;
        align-items: center; }
    .wrapper {
        width: 744px;
        .billings {
            margin-top: 16px;
            .title {
                margin-top: 16px;
                font-size: 24px;
                font-weight: 600;
                line-height: 32.78px;
                text-align: left; }
            .details {
                width: 744px;
                gap: 0px;
                border-radius: 8px;
                // border: 1px solid #D9D9D9
                border: 1px solid #FF9933;
                background: #FFFFFF;
                // box-shadow: 0px 4px 18px 0px #BAC3D04D
                box-shadow: 0px 4px 18px 0px #FF993324;
                padding: 16px;
                .view-bill {
                    height: 37px;
                    padding: 0px 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 6px;
                    border: 1px solid #797979;
                    font-size: 13px;
                    font-weight: 600;
                    cursor: pointer;
                    margin-right: 10px; }
                .pay-now {
                    cursor: pointer;
                    height: 37px;
                    padding: 0px 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 6px;
                    opacity: 0px;
                    color: #FFF;
                    font-size: 13px;
                    font-weight: 600;
                    background: #121212; }
                .amount {
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32.78px;
                    text-align: left; }
                .status {
                    margin-left: 18px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #EFCF6F;
                    justify-content: center;
                    font-size: 11px;
                    font-weight: 600;
                    background: #FFEFC0;
                    padding: 8px;
                    gap: 5px;
                    border-radius: 40px;
                    height: 24px;
                    .dot {
                        width: 4px;
                        height: 4px;
                        border-radius: 15px;
                        background: #FF9933; } } } }
        .manage-subscriptions {
            margin-top: 20px;
            .trail {
                background: #C8003630;
                border-radius: 16px;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 11px;
                color: #C80036;
                margin-left: 10px; }
            .pendingCancellation {
                background: #FF480032;
                border-radius: 16px;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 11px;
                color: #FF4800;
                margin-left: 10px; }
            .active {
                background: #29BB8932;
                border-radius: 16px;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 11px;
                color: #29BB89;
                margin-left: 10px; }
            .cancelled {
                background: #C8003630;
                border-radius: 16px;
                padding: 4px 8px;
                text-transform: uppercase;
                font-size: 11px;
                color: #C80036;
                margin-left: 10px; }
            .title {
                font-size: 15px;
                font-weight: 600;
                line-height: 20.49px;
                color: #797979; }
            .details {
                margin-top: 6px;
                width: 744px;
                border-radius: 8px;
                background: #FFF;
                border: 1px solid #D9D9D9;
                box-shadow: 0px 4px 18px 0px #BAC3D04D;
                padding: 16px;
                .billed {
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 500;
                    color: #474747; }
                .cancel-subscription {
                    cursor: pointer;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 17.76px;
                    color: #121212;
                    border: 1px solid #E8E8E8;
                    background: #F3F3F3;
                    height: 28px;
                    padding: 0px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px; }
                .amount {
                    font-size: 20px;
                    font-weight: 600;
                    color: #121212;
                    line-height: 27.32px; }
                .gst {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 31.42px;
                    color: #5A5A5A;
                    margin-left: 8px; } } }
        .app-subscriptions {
            margin-top: 20px;
            .no-app {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 74px 0px;
                .desc {
                    margin-top: 18px;
                    width: 243px;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 22px;
                    text-align: center;
                    color: #707070; } }
            .title {
                font-size: 15px;
                font-weight: 600;
                color: #797979; }
            .details {
                margin-top: 6px;
                width: 744px;
                border-radius: 8px;
                background: #FFF;
                border: 1px solid #D9D9D9;
                box-shadow: 0px 4px 18px 0px #BAC3D04D;
                padding: 16px;
                .price {
                    font-size: 20px;
                    font-weight: 600;
                    color: #121212; }
                .app-name {
                    font-size: 15px;
                    font-weight: 600background; }
                .next-bill {
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 500;
                    color: #474747;
                    margin-right: 4px; }
                .bill-date {
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 700;
                    color: #121212; }
                .cancel-subscription {
                    cursor: pointer;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 17.76px;
                    color: #121212;
                    border: 1px solid #E8E8E8;
                    background: #F3F3F3;
                    height: 28px;
                    padding: 0px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px; } } } }
    .trail-period {
        margin-top: 66px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 558px;
        .title {
            margin-top: 30px;
            font-size: 20px;
            font-weight: 600;
            line-height: 27.32px;
            text-align: left;
            color: #121212; }
        .desc {
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;
            text-align: center;
            color: #6D6D6D;
            margin-top: 18px; }
        .cancel-subscription {
            cursor: pointer;
            font-size: 13px;
            font-weight: 500;
            line-height: 17.76px;
            color: #121212;
            border: 1px solid #E8E8E8;
            background: #F3F3F3;
            height: 40px;
            padding: 0px 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            margin-top: 14px; } } }
.cancel-subscription-popup {
    position: absolute;
    top: 0;
    left: 0;
    background: #12121228;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        border: 1px solid #D9D9D9;
        box-shadow: 0px 4px 14px 0px #00000040;
        background: #fff;
        width: 470px;
        border-radius: 14px;
        .header {
            padding: 14px 16px;
            font-size: 16px;
            font-weight: 600;
            color: #121212;
            border-bottom: 1px solid #D9D9D9; }
        .footer {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0px 10px;
            margin-top: 4px;
            margin-bottom: 12px;
            .stay {
                cursor: pointer;
                height: 32px;
                padding: 0px 18px;
                border-radius: 5px;
                border: 1px solid #D9D9D9;
                font-size: 13px;
                font-weight: 500;
                justify-content: center;
                display: flex;
                align-items: center;
                margin-right: 10px; }
            .cancel {
                height: 32px;
                width: auto;
                padding: 0px 18px;
                flex-shrink: 0;
                border-radius: 5px;
                font-size: 13px;
                font-weight: 500;
                justify-content: center;
                display: flex;
                color: #FFF;
                align-items: center; } }
        .contain {
            padding: 18px 16px;
            .row {
                display: flex;
                align-items: center; }
            .title {
                color: #292D32;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 14px; }
            .desc {
                font-size: 14px;
                color: #726f6f;
                display: flex;
                flex-wrap: wrap;
                font-weight: 400; }
            .terms {
                font-size: 14px;
                font-weight: 500;
                color: #292D32;
                margin: 0px 4px; }
            .accept {
                flex-shrink: 0;
                width: 16px;
                cursor: pointer;
                height: 16px;
                border: 1px solid #606060;
                border-radius: 4px;
                margin-right: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 14px;
                .checked {
                    background: #121212;
                    width: 12px;
                    height: 12px;
                    border-radius: 4px; } } } } }

.billing-information {
    display: flex;
    justify-content: center;
    // align-items: center
    height: 84vh;
    .row {
        display: flex;
        align-items: center; }
    .wrapper {
        width: 744px;
        margin-top: 16px;
        .title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32.78px; }
        .edit {
            height: 31px;
            width: auto;
            padding: 0px 22px;
            border-radius: 6px;
            border: 1px solid #D9D9D9;
            box-shadow: 0px 4px 18px 0px #BAC3D04D;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFF;
            color: #121212;
            cursor: pointer; }
        .details {
            margin-top: 8px;
            margin-bottom: 8px;
            width: 744px;
            gap: 0px;
            border-radius: 8px;
            border: 1px solid #D9D9D9;
            background: #FFFFFF;
            box-shadow: 0px 4px 18px 0px #BAC3D04D;
            padding: 16px;
            .label {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.12px;
                width: 50%;
                color: #5A5A5A; }
            .value {
                width: 50%;
                font-size: 14px;
                font-weight: 500;
                line-height: 19.12px; } } } }

.billing-information-edit {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-footer {
        height: 66px;
        border-top: 1px solid #E5E5E5;
        display: flex;
        justify-content: flex-end;
        align-items: center; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        .group {
            margin-bottom: 18px;
            display: flex;
            flex-direction: column;
            .company {
                width: 42px;
                height: 20px; }
            .ant-switch-handle {
                width: 16px;
                height: 16px; }
            .ant-switch-checked {
                background: #121212; }
            .label {
                font-size: 13px;
                font-weight: 500;
                line-height: 17.76px;
                color: #4E4E4E; }
            input {
                margin-top: 8px;
                padding: 12px 10px;
                border: 1px solid #D9D9D9; } } } }

.invoice {
    background: #D9D9D9;
    height: 100%;
    width: 100%;
    .status {
        font-weight: 600;
        text-transform: uppercase; }
    .paid {
        color: #07c180; }
    .pending {
        color: #ff950a; }
    .content {
        box-shadow: 0px 4px 16px 0px #C6C6C61F;
        border: 1px solid #D9D9D9;
        background: #FFF;
        border-radius: 8px;
        margin: 16px; } }
