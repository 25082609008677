.settings {
    display: flex;
    .ant-input[disabled] {
        background: #FFF; }
    .home-page {
        .item {
            border: 1px solid #EFEFEF;
            border-radius: 10px;
            margin: 14px;
            padding: 20px; }
        .drag_item {
            cursor: row-resize;
            display: flex;
            .left {
                display: flex;
                flex-grow: 1;
                img {
                    margin-right: 20px; } }
            .right {
                cursor: pointer; } } }
    .custom-domain {
        margin: 0px 0px 0px 15px;
        display: flex;
        flex-grow: 1; }
    .code-injection {
        margin: 0px 25px;
        .division {
            margin-bottom: 24px; }
        .title {
            font-size: 15px;
            font-weight: bold; }
        .subtitle {
            font-size: 14px;
            color: #4E4E4E; }
        .monaco-editor, .monaco-editor .inputarea.ime-input {
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            overflow: hidden; } }
    .menus {
        display: flex;
        flex-direction: column;
        width: 257px;
        flex-shrink: 0;
        overflow-y: auto;
        padding: 13px;
        height: 94vh;
        border-right: 1px solid #D5D5D5;
        .item {
            padding: 0px 23px;
            font-size: 15px;
            border-radius: 4px;
            height: 40px;
            color: #121212;
            display: flex;
            align-items: center;
            margin: 6px 0px;
            cursor: pointer; }
        .selected {
            cursor: default;
            color: #121212;
            background: #EFEFEF;
            border-radius: 4px;
 } }            // box-shadow: 0px 3px 6px #EFEFEF
    .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: #F5F5F5;
        overflow-y: auto;
        .container {
            padding: 24px 16px;
            .header {
                color: #121212;
                font-size: 18px;
                font-weight: bold;
                display: flex; }
            .section {
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                border-radius: 10px;
                padding: 21px;
                margin-top: 15px; }
            .division {
                margin-bottom: 24px; }
            .title {
                font-size: 15px;
                font-weight: bold; }
            .key {
                font-size: 14px;
                color: #4E4E4E; }
            .link {
                font-size: 14px;
                color: #188FFC; }
            .value {
                font-size: 13px;
                font-weight: 400;
                color: #121212;
                line-height: 1.85; }
            .upload_icon {
                width: 202px;
                height: 138px;
                background: #FFFFFF;
                border: 1px solid #cbcbcb;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                img {
                    width: 66px;
                    object-fit: cover;
                    div {
                        width: 50%; } } }
            .md-editor .section {
                border: none;
                padding: 0;
                margin: 0; } } } }
