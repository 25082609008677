.product {
    // padding: 14px 28px
    // .ant-select
    //     min-width: 200px
    //     border-radius: 5px
    //     border-width: 0px
    //     .ant-select-multiple .ant-select-selector, .ant-select-selector
    //         border-radius: 5px !important
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .head {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font: normal normal 900 18px/25px AvenirMedium;
        letter-spacing: 0px;
        color: #000000; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .date-range {
                position: relative;
                background: #FFFFFF;
                border: 1px solid #ccc;
                border-radius: 5px;
                height: 40px;
                padding-left: 20px;
                display: flex;
                justify-content: center;
                .ant-picker {
                    border: none; }
                .ant-picker-input {
                    width: 108px; } }
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 430px; }
            .title {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                float: left;
                margin: 10px 25px;
                line-height: 2em; }
            .multi-select {
                width: 240px; }
            .btn {
                // border-radius: 5px
                // height: 30px
                // font-size: 12px
                // width: 180px
                margin: 0px 10px;
                // color: #fff
                // background: #000000 0% 0% no-repeat padding-box
                // padding: 0px
                // font-weight: 500
                // letter-spacing: -0.032em
                // box-shadow: 0px 0px 12px #96a9c340
                // border: 0px
                // font-family: 'AvenirMedium'
 } }                // line-height: 2rem
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                .ant-col {
                    padding-right: 20px; } }
            .filters {
                margin-top: 7px;
                margin-left: 30px;
                margin-bottom: 15px;
                margin-right: 30px;
                .ant-input-suffix {
                    @media screen and (min-width: 900px) and (max-width: 1400px) {
                        line-height: 1; }
                    @media screen and (min-width: 1400px) {
                        line-height: 2; }
                    img {
                        @media screen and (min-width: 900px) and (max-width: 1400px) {
                            width: 15px;
                            height: 20px; }
                        @media screen and (min-width: 1400px) {
                            width: 20px;
                            height: 24px; } } }
                .ant-col {
                    padding-right: 20px; }
                // .ant-input-affix-wrapper
                //     background: #FFFFFF 0% 0% no-repeat padding-box
                //     border: 1px solid #ccc
                //     border-radius: 5px
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                .item:hover {
                    background: #f5f5f5;
                    cursor: pointer; }
                .item {
                    cursor: default;
                    background: #fff;
                    padding: 0px 30px;
                    display: flex;
                    align-items: center;
                    height: 64px;
                    border-top: 1px solid #f1f1f1;
                    .category {
                        padding: 2px;
                        .ant-badge {
                            margin: 2px 4px; }
                        .ant-badge-not-a-wrapper .ant-badge-count {
                            background: #DBE2EA6C;
                            color: #000;
                            border-radius: 4px;
                            height: 26px;
                            padding: 4px 10px; } }
                    .instock {
                        color: #07C180; }
                    .outOfStock {
                        color: #ED1536;
                        font-weight: 600; } } } }
        .active {
            width: 2px;
            height: 100%;
            background-color: #46b275;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; }
        .inactive {
            width: 2px;
            height: 100%;
            background-color: #a8abbd;
            float: left;
            top: 0px;
            left: 0px;
            position: absolute; } } }
.product-view {
    // padding: 0px 28px
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        // box-shadow: 0px 0px 10px #96A9C340
        // border-radius: 5px
        width: 100%;
        height: 100%;
        .details {
            padding: 6px 40px;
            .basic-info {
                font: 900 22px AvenirMedium;
                letter-spacing: 0px;
                color: #fff; }
            .group {
                margin: 16px 0px;
                .markdown {
                    margin-top: 10px;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    overflow: hidden;
                    .preview .rc-md-editor {
                        border: none; } }
                .label {
                    height: 19px;
                    text-align: left;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4E4E;
                    margin-bottom: 8px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    img {
                        cursor: pointer; } }
                .outlets {
                    height: 200px;
                    overflow-y: auto;
                    .item {
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #ADB7C3;
                        border-radius: 5px;
                        padding: 16px 10px;
                        .name {
                            font: 16px/22px AvenirMedium;
                            letter-spacing: -0.51px;
                            color: #071E3D; }
                        .label {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #576B83; }
                        .value {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #00BDFF;
                            border: none; }
                        .instock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #07C180; }
                        .outstock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #ED1536; } } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            padding-left: 40px;
            // .save
            //     width: 165px
            //     background: #FFF
            //     color: #000
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            // .submit
            //     width: 146px
            //     height: 40px
            //     background: #11A1FD 0% 0% no-repeat padding-box
            //     font-size: 15px
            //     letter-spacing: 0px
            //     color: #FFFFFF
            //     border-radius: 5px
            // .cancel
            //     margin-right: 40px
            //     border: none
            //     font-size: 15px
            //     letter-spacing: 0px
 } } }            //     color: #7D9EB5
.product-img-uploader {
    .ant-upload-select-picture-card {
        width: 130px !important;
        height: 130px !important;
        border-radius: 6px !important;
        border: 1px dashed #707070 !important;
        background: #FFF !important; }
    .ant-upload-list-picture-card-container {
        width: 130px !important;
        height: 130px !important;
        border-radius: 6px !important;
        border: 1px dashed #707070 !important; }
    .ant-upload-list-picture-card .ant-upload-list-item {
        padding: 4px !important; }
    .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
        border: none !important; } }
