.setup-store {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: url(../../../assets/images/doodle.svg);
    .error {
        font-size: 12px;
        letter-spacing: 0px;
        color: #BD1616;
        margin-top: 2px; }
    .header {
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 35px;
        .left {
            display: flex;
            align-items: center;
            .logo {
                display: flex;
                align-items: center;
                cursor: pointer;
                img {
                    width: 68px;
                    margin-right: 37; }
                label {
                    font-size: 30px;
                    font-weight: bold;
                    padding-top: 10px; } }
            .create_store {
                width: 178px;
                height: 36px;
                background: #FFFFFF;
                border: 1px solid #000000;
                border-radius: 22px;
                margin-left: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                cursor: pointer; } }
        .right {
            .pp_tc {
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0px;
                color: #000000;
                display: flex;
                cursor: pointer;
                align-items: center;
                .logout {
                    padding: 0px 34px;
                    height: 36px;
                    color: #FFF;
                    background: #000000;
                    border: 1px solid #0000;
                    border-radius: 22px;
                    margin-left: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer; }
                .pp {
                    margin-right: 32px; } } } }
    .content {
        flex-grow: 1;
        margin-top: 120px;
        display: flex;
        overflow: auto;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        .company-check {
            width: 42px;
            height: 20px;
            border-radius: 50px; }
        .title {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 50px; }
        .subtitle {
            font-size: 16px;
            color: #5A5A5A;
            display: flex;
            margin-bottom: 29px;
            .highlight {
                font-size: 16px;
                font-weight: 900;
                letter-spacing: 0px;
                color: #000000;
                margin: 0px 4px; } }
        .staff {
            max-width: 80%;
            display: flex;
            overflow: auto;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 40px;
            .no_store {
                margin-top: 30px;
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                z-index: 0;
                svg {
                    position: absolute;
                    z-index: 90; } }
            .store_create_title {
                font-size: 18px;
                letter-spacing: 0px;
                color: #808A96;
                margin-bottom: 20px; }
            .alice-carousel__wrapper {
                margin-bottom: 32px;
                margin-top: 82px;
                height: 154px; }
            .alice-carousel__stage-item {
                margin-right: 20px; }
            .alice-carousel__wrapper {
                overflow: unset; }
            .store {
                cursor: pointer;
                width: 140px;
                margin: 10px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                -webkit-transition: 0.15s linear;
                -moz-transition: 0.15s linear;
                -o-transition: 0.15s linear;
                -ms-transition: 0.15s linear;
                transition: 0.15s linear;
                -webkit-transition-property: -webkit-transform margin;
                -moz-transition-property: -moz-transform margin;
                -o-transition-property: -o-transform margin;
                -ms-transition-property: -ms-transform margin;
                transition-property: transform margin;
                // -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.45, transparent), to(rgba(255, 255, 255, 0.25)))
                .item {
                    width: 112px;
                    height: 112px;
                    background: #FFF;
                    box-shadow: 0px 13px 26px #00000042;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    justify-content: center;
                    .not_active {
                        background: #f20808;
                        height: 28px;
                        width: 28px;
                        position: absolute;
                        color: #FFF;
                        font-size: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;
                        padding-bottom: 4px;
                        top: -8px;
                        right: -12px;
                        border: 2px solid #f20808; }
                    .letter {
                        font-size: 24px;
                        background: #121212;
                        color: #FFF;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50px;
                        height: 50px; }
                    .logo {
                        max-height: 60px;
                        width: 60px; } }
                .type_owner {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #07C180;
                    text-transform: capitalize;
                    margin-top: 6px;
                    font-weight: bold; }
                .type_working, .type_staff {
                    font-size: 14px;
                    letter-spacing: 0px;
                    color: #0076FF;
                    text-transform: capitalize;
                    margin-top: 6px;
                    font-weight: bold; }
                .name {
                    margin-top: 27px;
                    font-size: 17px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-break: break-word; } }
            .store:hover {
                -webkit-transform-origin: center bottom;
                -moz-transform-origin: center bottom;
                -o-transform-origin: center bottom;
                -ms-transform-origin: center bottom;
                transform-origin: center bottom;
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -o-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2); } }
        .business {
            .group {
                margin-bottom: 20px; }
            .label {
                font-size: 16px;
                letter-spacing: 0px;
                color: #000000;
                margin-bottom: 7px; }
            input {
                height: 44px;
                border: 1px solid #1A1A1A;
                border-radius: 5px;
                letter-spacing: 0px; }
            .tc {
                margin-top: 26px; }
            .agree {
                font: 13px AvenirMedium;
                letter-spacing: 0px;
                color: #000; }
            .sure {
                font: 13px AvenirMedium;
                letter-spacing: 0px;
                color: #33E1BE;
                text-decoration: underline; } }
        .otp {
            .label {
                font-size: 16px;
                letter-spacing: 0px;
                color: #000000;
                margin-bottom: 7px; }
            .resend {
                margin-top: 13px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 16px;
                .action {
                    font-size: 16px;
                    letter-spacing: 0px;
                    text-align: right;
                    cursor: pointer;
                    margin-left: 10px; } }
            .number {
                width: 350px;
                height: 44px;
                background: #FFFFFF;
                border: 1px solid #1A1A1A;
                border-radius: 5px;
                display: flex;
                margin-bottom: 51px;
                align-items: center;
                .country_code {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #808A96;
                    width: 54px; }
                .divider {
                    height: 28px;
                    width: 1px;
                    margin-right: 10px;
                    background: #ADB7C3; } } }
        input::-webkit-input-placeholder {
            color: #808A96; }
        input:-ms-input-placeholder {
            color: #808A96; }
        input::placeholder {
            color: #808A96; }
        input {
            color: #000;
            border: none;
            outline: none !important;
            border-radius: 2px;
            letter-spacing: 0px;
            padding: 0px 10px; }
        button {
            width: 350px;
            height: 44px;
            background: #121212;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            letter-spacing: 0px;
            color: #FFFFFF;
            border: none; } }

    .footer {
        height: 50px;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center; } }
