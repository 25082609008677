.Wrapper {
  list-style: none;
  box-sizing: border-box;
  padding-left: var(--spacing);
  margin-bottom: -1px;

  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    margin-left: 10px;
    margin-top: 5px;

    .TreeItem {
      /* --vertical-padding: 5px; */
      background: #FFF;
      height: 44px;
      width: 100%;
      border-radius: 10px;
      padding: 0px 13px;
      display: flex;
      align-items: center;
      border: 1px solid #E1E1E1;
      padding-right: 24px;
      box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
    }
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .TreeItem {
        position: relative;
        padding: 0 !important;
        height: 3px !important;
        border-color: rgb(33, 192, 130);
        background-color: rgb(33, 192, 130);

        &:before {
          position: absolute;
          left: -8px;
          top: -6px;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid rgb(33, 192, 130);
          background-color: #ffffff;
        }

        >* {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .TreeItem>* {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.TreeItem {
  background: #FFF;
  height: 44px;
  width: 100%;
  border-radius: 10px;
  padding: 0px 13px;
  display: flex;
  align-items: center;
  border: 1px solid #E1E1E1;
  justify-content: space-between;
  /* margin: 12px 0px; */
}

.Text {
  flex-grow: 1;
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.Count {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #121212;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {

  .Text,
  .Count {
    user-select: none;
    -webkit-user-select: none;
  }
}

.Collapse {
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }
}