.branding {
    display: flex;
    .menu {
        width: 250px;
        .item {
            height: 40px;
            border-radius: 4px;
            font-size: 16px;
            letter-spacing: 0px;
            color: #4E4E4E;
            cursor: pointer;
            padding: 23px;
            margin: 13px;
            display: flex;
            align-items: center;
            justify-content: flex-start; }
        .selected {
            cursor: default;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #121212;
            background: #EFEFEF; } }
    .contents {
        border-left: 1px solid #D5D5D5;
        flex-grow: 1;
        background: #F8F8F8;
        .ant-switch-checked {
            background-color: #000; }
        .section {
            background: #FFFFFF;
            border: 1px solid #EFEFEF;
            border-radius: 10px;
            margin: 14px;
            padding: 20px;
            .toggle_title {
                font-size: 16px;
                letter-spacing: 0px;
                color: #121212; }
            .input {
                height: 44px;
                background: #FFFFFF;
                border: 1px solid #CBCBCB;
                border-radius: 4px;
                font-size: 15px;
                letter-spacing: 0px;
                color: #121212;
                input {
                    border: none !important;
                    height: 34px;
                    padding-top: 5px; } }
            .title {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0px;
                color: #121212; }
            .subtitle {
                font-size: 13px;
                letter-spacing: 0px;
                color: #121212; } }
        .brand_perference {
            .markdown {
                margin-top: 10px;
                border: 1px solid #ADB7C3;
                border-radius: 4px;
                overflow: hidden;
                width: 400px;
                .preview {
                    .rc-md-editor {
                        border: none;
                        .section {
                            padding: 0px;
                            margin: 0px;
                            border: none; } } }
                .label {
                    height: 19px;
                    text-align: left;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4E4E;
                    margin-bottom: 8px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    img {
                        cursor: pointer; } } }
            .section {
                display: flex; }
            .desc {
                width: 40%; }
            .val {
                display: flex;
                align-items: flex-end;
                position: relative; }
            .tag {
                position: relative;
                margin-left: -120px;
                background: #121212;
                width: 106px;
                border-radius: 4px;
                color: #fff;
                text-align: center;
                font-weight: 700;
                padding: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: #00000029 0px 3px 6px; }
            .title {
                margin-bottom: 10px; } }
        .upload_icon {
            width: 202px;
            height: 138px;
            background: #FFFFFF;
            border: 1px solid #cbcbcb;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            img {
                width: 66px;
                object-fit: cover;
                div {
                    width: 50%; } } }
        .code-injection {
            .monaco-editor, .monaco-editor .inputarea.ime-input {
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                overflow: hidden; } }
        .features {}
        .business_hours {
            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 23px 14px;
                .title {
                    font-weight: bold;
                    fon-sizet: 18px;
                    letter-spacing: 0px;
                    color: #000000;
                    margin-bottom: 12px; }
                .subtitle {
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4E4E; } } } } }
.business_hours_view_edit {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        padding: 20px 17px;
        .group {
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            justify-content: flex-start; }
        .label {
            font-size: 16px;
            letter-spacing: 0px;
            color: #4E4E4E;
            margin-bottom: 8px; }
        .days_tab {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .day {
                width: 56px;
                height: 44px;
                background: #CBCBCB50;
                border: 1px solid #CBCBCB50;
                border-radius: 6px;
                margin-right: 2px;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #121212;
                &.selected {
                    background: #FFFFFF;
                    font-weight: bold;
                    border: 1px solid #1A1A1A;
                    cursor: default; }
                .filled {
                    width: 38px;
                    background: #121212;
                    height: 2px;
                    position: absolute;
                    margin-top: 42px; } } }

        .ant-input:focus, .ant-input-focused {
            border: 1px solid #121212; }
        .custom_timing_hours {
            margin-top: 10px; } } }
