.faq {
    font-size: 15px;
    letter-spacing: 0px;
    .action-bar {
        background: #f5f5f5;
        height: 60px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #E7EEF7;
        text-align: right;
        padding: 0px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 4px;
            width: 400px;
            button {
                width: 130px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 12px; }
            input {
                outline: none;
                padding: 0 10px;
                flex-grow: 1;
                border: 1px solid #DBDBDB;
                width: 364px;
                display: flex;
                -webkit-align-items: center;
                align-items: center; } }
        .title {
            font-size: 14px;
            letter-spacing: 0px;
            color: #404040;
            float: left;
            margin: 10px 25px;
            line-height: 2em; } }
    .content {
        display: flex;
        .questions {
            flex-grow: 1;
            .add-qna {
                height: 40px;
                background: #FFFFFF;
                border: 1px dashed #697B95;
                border-radius: 5px;
                font-size: 14px;
                letter-spacing: -0.45px;
                color: #697B95;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 20px;
                cursor: pointer; }
            .title {
                height: 50px;
                display: flex;
                align-items: center;
                padding: 0px 16px;
                img {
                    margin-left: 10px;
                    height: 16px;
                    cursor: pointer; }
                .edit-text {
                    border: 1px solid #DBDBDB;
                    width: 364px;
                    display: flex;
                    align-items: center;
                    input {
                        outline: none;
                        border: none;
                        padding: 0 16px; }
                    img {
                        height: 20px; } } }
            .items {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 0px 16px 16px 16px;
                overflow-y: scroll;
                .item {
                    margin: 8px 0px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #DBDBDB;
                    border-radius: 5px;
                    padding: 14px 10px 0px 14px;
                    overflow: hidden;
                    align-content: center;
                    .answ {
                        overflow-y: auto;
                        transition: max-height 0.50s ease-out;
                        .rc-md-editor {
                            border-width: 0px;
                            .html-wrap {
                                padding: 0px; }
                            p {
                                font-size: 14px;
                                letter-spacing: -0.45px;
                                color: #697B95; } } }
                    .ques {
                        cursor: pointer;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        .action {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            img {
                                height: 20px;
                                margin-left: 12px;
                                cursor: pointer; } }
                        label {
                            font-size: 14px;
                            letter-spacing: -0.45px;
                            color: #000000; }
                        .switch .ant-switch-checked {
                            background-color: #33E1BE; } } } } }
        .categories {
            width: 350px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px #96A9C340;
            border-radius: 5px;
            .search {
                height: 50px;
                display: flex;
                align-items: center;
                border-bottom-color: #EBEBEB;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                padding-right: 6px;
                input {
                    padding: 0 10px;
                    border: none;
                    outline: none; } }
            .items {
                display: flex;
                align-items: center;
                flex-direction: column;
                .item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                    padding: 0px 10px;
                    .inactive {
                        font-size: 14px;
                        letter-spacing: -0.45px;
                        color: #697B95;
                        cursor: pointer; }
                    .active {
                        font-size: 14px;
                        letter-spacing: -0.45px;
                        color: #000000;
                        cursor: pointer; }
                    .switch .ant-switch-checked {
                        background-color: #33E1BE; } } } } } }
.faq-save {
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden;
        background: #fff; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        .title {
            color: #000;
            font: 900 24px AvenirMedium;
            text-transform: uppercase;
            margin-left: 52px; }
        .back {
            position: absolute;
            color: #000;
            left: 16px;
            font-size: 24px;
            cursor: pointer; } }
    .content {
        .group {
            padding: 24px 52px;
            width: 100%;
            height: 60px;
            @media screen and (min-width: 900px) and (max-width: 1400px) {
                margin-bottom: 22px; }
            @media screen and (min-width: 1400px) {
                margin-bottom: 46px; }
            .stop-editing {
                position: absolute;
                width: 100%;
                height: 100%; }
            .rc-md-editor {
                border-radius: 4px;
                .editor-container {
 }                    // flex-direction: column
                .sec-html {
                    // border-top-color: #080808
                    // border-top-width: 2px
 }                    // border-top-style: dashed
                .right {
                    display: none; } }
            .label {
                height: 19px;
                text-align: left;
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #A8A8A8;
                margin-bottom: 10px; }
            input, textarea {
                width: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border-radius: 4px; } } } }
