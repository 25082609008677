.signin_mob {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background: url(../../../assets/images/doodle.svg);
    .section_1 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            padding: 0px 20px;
            .motto {
                margin-top: 55px;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 24px;
                text-align: center; }
            .desc {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 55px;
                line-height: 28px;
                text-align: center; }
            .oauth {
                flex-grow: 1;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 50%;
                button {
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    font-size: 14px;
                    width: 313px;
                    height: 64px;
                    background: #1A1A1A;
                    border-radius: 10px;
                    margin-bottom: 28px;
                    font-weight: 500;
                    cursor: pointer;
                    label {
                        cursor: pointer;
                        padding-top: 4px; } }
                .google {
                    cursor: pointer;
                    img {
                        width: 28px;
                        margin-right: 16px; } } } } }
    .footer {
        font-size: 12px;
        font-weight: 500;
        color: #5A5A5A;
        text-align: center;
        padding-bottom: 17px; } }
.email-login {
    .section_1 {
        align-items: center;
        .content {
            max-width: 375px;
            align-items: center; } }
    .motto {
        max-width: 300px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 0px;
        margin: 0px;
        height: auto;
        margin-bottom: 30px;
        .title {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            line-height: normal;
            margin-right: 6px; } }
    .code {
        color: #21C082;
        font-size: 24px;
        font-weight: 600;
        line-height: normal; }
    .title {
        color: #5A5A5A;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        line-height: normal;
        flex-wrap: wrap;
        justify-content: center;
        .title-plus {
            color: #000;
            font-size: 16px;
            font-weight: 600;
            margin: 0px 4px;
            word-break: keep-all; }
        div {
            flex: 1; } }
    .email-or-phone {
        border-radius: 4px;
        border: 1px solid #000;
        background: #FFF; }
    .register {
        color: #21C082;
        font-size: 20px;
        font-style: normal;
        font-weight: 500; }
    .new {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500; }
    .email {
        margin-top: 29px;
        border-radius: 4px;
        border: 1px solid #000;
        background: #FFF;
        height: 48px;
        padding: 0px 10px; }
    .error {
        color: #E00F0F;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        .message {
            font-size: 12px;
            color: #fff; } }
    .otp-action {
        display: flex;
        justify-self: flex-end;
        .timer {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            line-height: normal; }
        .resend-disable {
            color: #A7A7A7;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline; }
        .resend-enable {
            color: #4A9CFD;
            font-size: 14px;
            cursor: pointer;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline; } }
    .send-otp {
        margin-top: 32px;
        border-radius: 4px;
        border: 1px solid #000;
        background: #000;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
        height: 48px;
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        line-height: normal;
        width: 100%; }
    .new-reg {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 0px;
        margin-top: 40px;
        .new {
            color: #000;
            font-size: 20px;
            font-weight: 500;
            line-height: normal;
            margin-right: 6px; }
        .reg {
            color: #21C082;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
            line-height: normal; } } }
