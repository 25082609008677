@font-face {
    font-family: AvenirNext;
    src: url(../../fonts/Avenir/AvenirNext-Medium.otf); }

body {
    font-family: AvenirNext; }

// *:not(input)
//     -moz-user-select: -moz-none
//     -khtml-user-select: none
//     -webkit-user-select: none
//     -ms-user-select: none
//     user-select: none
.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }

::-webkit-scrollbar {
    width: 4px;
    height: 2px; }

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #FFF; }

::-webkit-scrollbar-thumb {
    background-color: #000000;
    outline: 2px solid #000000; }

.carousel .slide {
    background: #FFF !important; }

.ant-input:focus, .ant-input-focused {
    border: 1px solid #d9d9d9;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
.ant-input-affix-wrapper:hover {
    border: 1px solid #ccc; }
.ant-input-affix-wrapper > input.ant-input {
    height: 30px; }
.ant-input-affix-wrapper {
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 40px; }
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border: 1px solid #ccc;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
input {
    border-radius: 5px;
    background: #ffffff;
    width: 100%;
    color: #000;
    border: none;
    outline: none !important;
    // letter-spacing: 2px
    height: 40px; }
input::-webkit-input-placeholder {
    color: #808A96; }
input:-ms-input-placeholder {
    color: #808A96; }
input::placeholder {
    color: #808A96; }
.loading {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .animation {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            & div {
                position: absolute;
                top: 33px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: #fff;
                animation-timing-function: cubic-bezier(0, 1, 1, 0); }
            & div:nth-child(1) {
                left: 8px;
                animation: lds-ellipsis1 0.6s infinite; }
            & div:nth-child(2) {
                left: 8px;
                animation: lds-ellipsis2 0.6s infinite; }
            & div:nth-child(3) {
                left: 32px;
                animation: lds-ellipsis2 0.6s infinite; }
            & div:nth-child(4) {
                left: 56px;
                animation: lds-ellipsis3 0.6s infinite; } }
        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0); }

            100% {
                transform: scale(1); } }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1); }

            100% {
                transform: scale(0); } }
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0); }

            100% {
                transform: translate(24px, 0); } } } }
.shopletzy-app-mob {
    .fixed-layout {
        height: 100vh;
        padding: 0;
        display: flex;
        flex-direction: column; }
    .floating-layout {
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%; }
    // .ant-input-affix-wrapper
    //     height: 48px !important
    // .ant-input
 }    //     height: 40px !important
