.sp-header {
    width: 100%;
    .create-store {
        border: 1px solid #000000;
        max-width: 357px;
        height: 48px;
        border-radius: 54px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 45px;
        background: #fff; }
    .group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .logout {
            width: 95px;
            height: 36px;
            border-radius: 54px;
            color: #fff;
            background: #121212;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer; }
        .logo {
            display: flex;
            align-items: center;
            img {
                width: 48px;
                height: 52px; }
            label {
                font-size: 30px;
                font-weight: 600;
                padding-top: 6px;
                color: #121212;
                letter-spacing: 1px;
                margin-left: 11px; } } } }
