$card-radius: 20px;
$card-shadow: 0px 4px 90px rgba(163,171 ,185,0.24);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

body {
    background-color: #fbfcfc; }

// .menu
//     .ant-tabs-bar
//         border-bottom: 0px solid #f0f0f0
//     .tabs
//         padding: 0px 30px
.header-menu-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .title {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        // margin-top: 10px
        min-height: 32px;
        width: 100%;
        height: 100%; }
    .items {
        display: flex;
        color: #000;
        align-items: baseline;
        align-self: stretch;
        flex: 1 0 auto;
        justify-content: flex-start;
        max-width: 100%;
        // margin-top: 6px
        flex-direction: row;
        .label {
            display: flex;
            flex-direction: column;
            margin-right: 40px;
            position: relative;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            height: 100%; }
        .selected {
            color: #07c180; }
        .underline {
            width: 100%;
            border-bottom: 2px solid #07c180; } } }
