.left-nav {
    height: 100%;
    .main-menu {
        background: #121212;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 70px;
        z-index: 104; }
    .section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .logo {
            margin-top: 24px; }
        .menus {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 40px;
            .selected {
                background: #3C3C3C;
                border-radius: 4px;
                position: absolute; }
            ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                li {
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    cursor: pointer;
                    .tooltip {
                        display: flex;
                        align-items: center;
                        justify-content: center; } } } } }
    .new-lunch {
        background: linear-gradient(90deg, #4BA9FF, #FF67C2);
        background-size: 400% 400%;
        animation: gradient 5s ease infinite;
        padding: 2px 14px;
        margin-left: 10px;
        text-align: center;
        border-radius: 20px;
        color: #FFF;
        border-radius: 30px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: 500;
        @keyframes gradient {
            0% {
                background-position: 0; }
            50% {
                background-position: 100% 50%; }
            100% {
                background-position: 0% 50%; } } }
    .label-coming-soon {
        background: #ffe493;
        border-radius: 30px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: 500;
        width: 82px;
        color: #121212;
        margin-left: 10px; }
    .ext-menu {
        padding: 23px;
        width: 300px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 70px;
        transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
        background-color: #121212;
        z-index: 101;
        border-left: 1px solid #303030;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            .selected {
                background: #3C3C3C;
                border-radius: 4px;
                position: absolute; }
            .title {
                color: #999;
                font-size: 12px;
                font-weight: 500;
                margin-top: 30px; }
            li {
                color: #FFF;
                font-size: 14px;
                line-height: normal;
                margin-bottom: 18px; } } }
    .inactive {
        left: -550px; }
    .title {
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px; }
    .search input::placeholder {
        color: #ABABAB; }
    .search, .search input {
        border-radius: 6px;
        border: 1px solid #303030;
        background: #303030;
        input {
            color: #FFF;
            font-size: 14px;
            font-weight: 400; } } }
