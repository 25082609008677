.customer {
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .menu-option {
        position: absolute;
        background: #fff;
        display: flex;
        flex-direction: column;
        text-align: left;
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        padding: 14px 20px 0px;
        z-index: 9999;
        opacity: 1; }
    .head {
        font: 14px/19px AvenirNext;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirNext;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .header-title {
        font-size: 14px;
        letter-spacing: 0px;
        color: #404040;
        float: left;
        margin: 10px 25px;
        line-height: 2em; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .title {
                // font: 18px/25px AvenirNext
                // font-weight: 900
                // letter-spacing: 0px
                // color: #000000
                // float: left
 }                // margin: 10px 25px
            .btn {
                margin-left: 10px; } }
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirNext;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                // .ant-col
 }                //     padding-right: 20px
            .filters {
                margin: 15px 30px;
                .ant-col {
                    padding-right: 20px; }
                .ant-select {
                    width: 100%;
                    height: 34px;
                    .ant-select-selector {
                        height: 30px;
                        border-radius: 0px; } } }
            .contains {
                // overflow-y: auto
                .item {
                    padding: 0px 30px;
                    align-items: center;
                    .ant-col {
                        padding: 10px 0px;
                        opacity: 1; }
                    .ant-col:hover {
                        opacity: 0.6;
                        cursor: pointer; } } } } } }
.customer-orders {
    display: flex;
    background: #F8F8F8;
    .card {
        width: 303px;
        height: 92px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 10px 8px;
        margin: 10px;
        cursor: pointer;
        .label {
            font-size: 12px;
            letter-spacing: 0px;
            color: #4E4E4E;
            margin-right: 4px; }
        .value {
            font-size: 12px;
            letter-spacing: 0px;
            color: #121212; }
        .mode-cod {
            font-size: 12px;
            letter-spacing: 0px;
            color: #0051FF; } } }
.customer-view {
    .ant-drawer-body, .ant-drawer-footer {
        border-color: #FFF;
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        font-size: 12px;
        padding: 22px 0px;
        .link {
            cursor: pointer;
            font: 14px/19px AvenirNext;
            letter-spacing: 0px;
            color: #11A1FD; }
        .head {
            font: 14px/19px AvenirNext;
            letter-spacing: 0px;
            color: #000000; }
        .title {
            font: normal normal 900 18px/25px AvenirNext;
            letter-spacing: 0px;
            color: #000000; } }
    .content {
        overflow: hidden;
        // box-shadow: 0px 0px 10px #96A9C340
        // border-radius: 5px
        width: 100%;
        .details {
            padding: 26px;
            box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
            .basic-info {
                padding: 6px 40px;
                font: 900 22px AvenirNext;
                letter-spacing: 0px;
                color: #000;
                .name {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px; }
                .date {
                    text-transform: capitalize;
                    font-family: AvenirNext;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: #808080; }
                .value {
                    font-family: AvenirNext;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px; } }
            .group {
                margin: 16px 0px;
                .label {
                    font: 14px AvenirNext;
                    letter-spacing: 0px;
                    color: #A8A8A8;
                    margin-bottom: 10px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    .ant-select-selector {
                        display: flex;
                        align-items: center; } }
                .multiple {
                    .ant-select-selector {
                        padding-left: 4px; }
                    img {
                        cursor: pointer; } } } }
        .orders {
            background: #FAFAFA;
            padding: 26px;
            margin-top: 2px;
            .title {
                font-family: AvenirNext;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 12px;
                margin: 0px 26px;
                text-transform: uppercase; }
            .items {
                overflow-y: auto;
                padding: 26px;
                .item {
                    background: #FFFFFF;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    display: flex;
                    justify-content: space-between;
                    padding: 24px;
                    .date {
                        font-family: AvenirNext;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px; }
                    .products {
                        font-family: AvenirNext;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        color: #000000;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        padding-left: 24px;
                        .text {
                            margin-bottom: 12px;
                            font-family: AvenirNext;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px; } }
                    .status_price {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-end;
                        .status {
                            background: rgba(244, 18, 249, 0.07);
                            border-radius: 20px;
                            .text {
                                font-family: AvenirNext;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 16px;
                                color: #D800FB;
                                padding: 6px 14px; } }
                        .price {
                            font-family: AvenirNext;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            color: #50CB93;
                            margin-right: 14px; } } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            // padding-left: 40px
            padding: 6px 40px;
            // .save
            //     width: 165px
            //     background: #FFF
            //     color: #000
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            // .submit
            //     width: 146px
            //     height: 40px
            //     background: #11A1FD 0% 0% no-repeat padding-box
            //     font-size: 15px
            //     letter-spacing: 0px
            //     color: #FFFFFF
            //     border-radius: 5px
            // .cancel
            //     margin-right: 40px
            //     border: none
            //     font-size: 15px
            //     letter-spacing: 0px
 } } }            //     color: #7D9EB5
