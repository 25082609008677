/* @font-face{
  font-family: "AvenirHeavy";
  src: url("assets/fonts/Avenir/AvenirLTStd-Heavy.otf") format("otf");
}
@font-face{
  font-family: "AvenirMedium";
  src: url("assets/fonts/Avenir/AvenirLTStd-Light.otf") format("otf");
}
@font-face{
  font-family: "RobotoBlack";
  src: url("assets/fonts/Roboto/Roboto-Black.ttf") format("ttf");
} */

body {
  margin: 0;
  padding: 0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
