.lexi {
    @keyframes textShine {
        0% {
            background-position: 0% 50%; }
        100% {
            background-position: 100% 50%; } }
    .text-shine {
        background: linear-gradient(to right, #7953cd 20%, #00affa 30%, #0190cd 70%, #764ada 80%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-size: 500% auto;
        animation: textShine 5s ease-in-out infinite alternate; }
    .text-generating {
        height: auto;
        animation: scaleAnimation 5s infinite; }
    @keyframes scaleAnimation {
        0% {
            transform: scale(1); }
        50% {
            transform: scale(1.5); }
        100% {
            transform: scale(1); } }
    .open {
        background: linear-gradient(91.88deg, #B2FFDF -15.51%, #DAEDFF 61.68%, #9FFFF0 109.35%);
        font-size: 13px;
        font-weight: 500;
        padding: 6px 9px 6px 7px;
        gap: 6px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        div {
            line-height: normal; } }
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1001; }
    .popup {
        background: #C4C4C43E;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        .container {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            .box {
                border-radius: 16px;
                width: 845px;
                min-height: 232px;
                background: #fff 0% 0% no-repeat padding-box;
                box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 18px 24px;
                    border-bottom: 1px solid #C4C4C4B8;
                    .title {
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0px;
                        color: #121212;
                        text-transform: capitalize;
                        margin: 0px; }
                    .close {
                        width: 24px;
                        height: 24px;
                        cursor: pointer; } }
                .content {
                    overflow: hidden;
                    animation: heightEnlarge 2s ease-in-out forwards;
                    border: none;
                    border-bottom: 1px solid #C4C4C4B8;
                    .rc-md-editor {
                        overflow-y: auto;
                        // max-height: calc(100% - 2px)
                        max-height: 400px;
                        border-radius: 6px;
                        background: #F5F5F5; }
                    .rc-md-editor .editor-container>.section:last-child {
                        border: none; } }
                @keyframes heightEnlarge {
                    0% {
                        max-height: 100px; }
                    100% {
                        max-height: 450px; } }
                .user-input {
                    padding: 18px 24px;
                    label {
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 17.76px;
                        color: #4E4E4E; }
                    input {
                        margin-top: 6px;
                        padding: 8px 12px;
                        border: 1px solid #D9D9D9; } }
                .action {
                    justify-content: flex-end;
                    display: flex;
                    margin-top: 18px;
                    margin-bottom: 18px;
                    margin-right: 10px;
                    button {
                        width: auto;
                        font-weight: 800;
                        margin-right: 10px; }
                    .use-it {
                        padding: 15px 22px 15px 22px;
                        gap: 10px;
                        border-radius: 4px;
                        background: #34E5C1;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19.12px;
                        color: #121212; }
                    .generate {
                        padding: 14px 22px 14px 22px;
                        gap: 10px;
                        border-radius: 4px;
                        background: #121212;
                        font-size: 14px;
                        font-weight: 700; }
                    // .metal-button
                    //     padding: 15px 30px
                    //     font-size: 18px
                    //     font-weight: bold
                    //     color: #fff
                    //     background: linear-gradient(145deg, #121212, #121212)
                    //     border: 2px solid #777
                    //     border-radius: 10px
                    //     text-transform: uppercase
                    //     text-align: center
                    //     cursor: pointer
                    //     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2)
                    //     transition: transform 0.2s ease-in-out
                    //     position: relative
                    //     overflow: hidden
                    //     &::before
                    //         content: ''
                    //         position: absolute
                    //         top: -50%
                    //         left: -50%
                    //         width: 200%
                    //         height: 200%
                    //         background: radial-gradient(circle, rgba(255, 255, 255, 0.6), transparent 70%)
                    //         opacity: 1
                    //         transition: opacity 0.2s
                    //         animation: flash 1s infinite
                    //     &:active
                    //         transform: scale(0.95)
                    // @keyframes flash
                    //     0%
                    //         transform: translate(-50%, -50%) rotate(0deg)
                    //     100%
 } } } } }                    //         transform: translate(-50%, -50%) rotate(360deg)
