.nav-bar {
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    .icons {
        box-shadow: 0px 0px 10px #00000033;
        background: #000;
        border-radius: 10px;
        margin: 0px 14px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // width: 100%
        height: 56px;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            .box {
                width: 0px;
                height: 0px;
                position: absolute; }
            .selected {
                width: 50px;
                height: 42px;
                transition: 0.5s;
                background: #8b8b8b6e;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px; } } } }
