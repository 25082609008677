.employee {
    .ant-select-selector {
        display: flex;
        align-items: center;
        justify-content: center; }
    .menu-option {
        position: absolute;
        background: #fff;
        display: flex;
        flex-direction: column;
        text-align: left;
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        padding: 14px 20px 0px;
        z-index: 9999;
        opacity: 1; }
    .head {
        font: 14px/19px AvenirMedium;
        letter-spacing: 0px;
        color: #000000;
        height: 26px;
        .link {
            font: 12px/16px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD;
            cursor: pointer; }
        .path-arrow {
            color: #697B95;
            font-size: 12px;
            padding: 0px 8px 0px 4px; } }
    .title {
        font: 14px AvenirMedium !important;
        font: 14px AvenirMedium;
        letter-spacing: 0px;
        color: #404040;
        float: left;
        margin: 10px 25px;
        line-height: 2em; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .action-bar {
            background: #f5f5f5;
            height: 60px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: #E7EEF7;
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .title {
                // font: 18px/25px AvenirMedium
                // font-weight: 900
                // letter-spacing: 0px
                // color: #000000
                // float: left
 }                // margin: 10px 25px
            .btn {
                margin-left: 10px; } }
        .content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            .header {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                padding: 0px;
                margin-top: 15px;
                margin-left: 30px;
                margin-right: 30px;
                // .ant-col
 }                //     padding-right: 20px
            .filters {
                margin: 15px 30px;
                .ant-col {
                    padding-right: 20px; }
                // .ant-select
                //     width: 100%
                //     height: 34px
                //     .ant-select-selector
                //         height: 30px
 }                //         border-radius: 0px
            .contains {
                overflow-y: auto;
                .item {
                    padding: 0px 30px;
                    .ant-col {
                        padding: 10px 0px;
                        opacity: 1; }
                    .ant-col:hover {
                        opacity: 1;
                        cursor: pointer; } } } } } }
.employee-view {
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    // .header
    //     font-size: 12px
    //     padding: 22px 0px
    //     .link
    //         cursor: pointer
    //         font: 14px/19px AvenirMedium
    //         letter-spacing: 0px
    //         color: #11A1FD
    //     .head
    //         font: 14px/19px AvenirMedium
    //         letter-spacing: 0px
    //         color: #000000
    //     .title
    //         font: normal normal 900 18px/25px AvenirMedium
    //         letter-spacing: 0px
    //         color: #000000
    .content {
        // box-shadow: 0px 0px 10px #96A9C340
        // border-radius: 5px
        width: 100%;
        height: 100%;
        .details {
            padding: 6px 40px;
            .basic-info {
                font: 900 22px AvenirMedium;
                letter-spacing: 0px;
                color: #121212; }
            .group {
                margin: 16px 0px;
                .label {
                    height: 19px;
                    text-align: left;
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #4E4E4E;
                    margin-bottom: 8px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    .ant-select-selector {
                        display: flex;
                        align-items: center; } }
                .multiple {
                    .ant-select-selector {
                        padding-left: 4px; }
                    img {
                        cursor: pointer; } }
                .outlets {
                    height: 200px;
                    overflow-y: auto;
                    .item {
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #ADB7C3;
                        border-radius: 5px;
                        padding: 16px 10px;
                        .name {
                            font: 16px/22px AvenirMedium;
                            letter-spacing: -0.51px;
                            color: #071E3D; }
                        .label {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #576B83; }
                        .value {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #00BDFF;
                            border: none; }
                        .instock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #07C180; }
                        .outstock {
                            font: 14px/22px AvenirMedium;
                            letter-spacing: -0.45px;
                            color: #ED1536; } } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            // padding-left: 40px
            padding: 6px 40px;
            // .save
            //     width: 165px
            //     background: #FFF
            //     color: #000
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; }
            // .submit
            //     width: 146px
            //     height: 40px
            //     background: #11A1FD 0% 0% no-repeat padding-box
            //     font-size: 15px
            //     letter-spacing: 0px
            //     color: #FFFFFF
            //     border-radius: 5px
            // .cancel
            //     margin-right: 40px
            //     border: none
            //     font-size: 15px
            //     letter-spacing: 0px
 } } }            //     color: #7D9EB5
