.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px 6px;
    .v-line {
        background-color: #d8dee630;
        position: absolute;
        left: -42px;
        width: 2px;
        height: 100%;
        z-index: 1;
        top: 6px;
        transition: height 4s; } }

.timeline-item {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    padding-right: 10px;
    padding-left: 65px;
    position: relative;
    // margin: 10px 0
    width: 100%;
    // ::after
    //     background-color: #d8dee630
    //     content: ''
    //     position: absolute
    //     left: 0px
    //     width: 2px
    //     height: 100%
 }    //     z-index: 1

.timeline-item-content {
    border-radius: 5px;
    background-color: #000fff00;
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: relative;
    width: 100%;
    text-align: left;
    align-items: flex-start;
    ::after {
        right: auto;
        left: -7.5px; }
    .tag {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        top: 15px;
        left: 0px;
        right: 0px;
        letter-spacing: 0px;
        padding: 0px;
        position: absolute; } }

.timeline-item-content {
    @media only screen and (max-width: 1023px) {
        max-width: 100%; }
    p {
        font-size: 13px;
        line-height: 1px;
        margin: 0px 0;
        top: 10px;
        max-width: 250px; }

    .circle {
        background-color: #F1F3F6;
        border: 0px solid #121212;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 40px;
        left: -56px;
        width: 32px;
        height: 32px;
        z-index: 99; } }

.timeline-item-content, .timeline-item .timeline-item-content {}
@media only screen and (max-width: 767px) {
    padding: 15px 10px;
    text-align: center;
    align-items: center; }

// @media only screen and (max-width: 767px)
// .timeline-item-content, .timeline-item .timeline-item-content
//     padding: 15px 10px
//     text-align: center
//     align-items: center

.timeline-item-content {
    .tag {
        width: calc(100% - 10px);
        text-align: left; }
    .time {
        margin-top: 20px; }
    a {
        text-decoration: underline; }
    &::after {
        display: none; } }
