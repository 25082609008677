.header-section {
    height: 100%;
    padding: 0px 10px;
    width: 100%;
    flex-direction: row;
    display: flex;
    .toggle-menu {
        display: flex;
        align-items: center;
        margin-top: -4px; }
    .tab-menu {
        flex: 1;
        flex-grow: 1;
        padding-left: 20px;
        flex-direction: column;
        align-items: flex-start;
        .menu {
            display: flex;
            align-items: center;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start; } }
    .store {
        margin-right: 6px;
        display: flex;
        align-items: center;
        .status {
            display: flex;
            align-items: center;
            .color_code {
                width: 6px;
                height: 6px;
                border-radius: 15px;
                margin-left: 10px; }
            .text {
                font-weight: bold;
                text-transform: capitalize; } }
        .info {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-transform: uppercase; }
        img {
            margin-left: 10px; }
        .store_list {
            min-width: 136px;
            position: absolute;
            max-height: 400px;
            top: 65px;
            z-index: 9999;
            background: #FFF;
            padding: 10px 15px;
            right: 12px;
            overflow-y: auto;
            border-radius: 4px;
            border: 1px solid #D8DEE6;
            background: #FFF;
            box-shadow: 0px 0px 8px 0px rgba(26, 26, 26, 0.14);
            .recent {
                color: #949494;
                font-size: 11px;
                font-weight: 500; }
            .item-label {
                color: #292D32;
                font-size: 13px;
                font-weight: 500;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                text-transform: capitalize;
                cursor: pointer; }
            .switch-store {
                display: flex;
                width: 154px;
                height: 28px;
                cursor: pointer;
                padding: 11px 25px;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                background: #EBEBEB;
                color: #000;
                font-size: 13px;
                font-weight: 500;
                margin-top: 14px; }
            .divider {
                width: 100%;
                height: 1px;
                background: #E6E6E6;
                margin: 12px 0px; }
            &::-webkit-scrollbar {
                height: 0;
                width: 0; } } }

    .welcome {
        margin-right: 22px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        img {
            width: 38px;
            height: 38px;
            object-fit: contain;
            border-radius: 30px; } }
    .type_owner {
        font-size: 14px;
        letter-spacing: 0px;
        color: #07C180;
        text-transform: capitalize;
        font-weight: bold; }
    .type_working, .type_staff {
        font-size: 14px;
        letter-spacing: 0px;
        color: #0076FF;
        text-transform: capitalize;
        font-weight: bold; }
    .locale {
        display: flex;
        justify-content: center;
        align-items: center; } }
