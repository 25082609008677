.dragdrop-upload {
    position: relative;
    background: #FFF;
    display: flex;
    .preview-panel {
        position: absolute;
        top: 0;
        background: rgba(0, 0, 0, 0.74);
        width: 100%;
        z-index: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .back {
            position: absolute;
            top: 20px;
            left: 0px;
            cursor: pointer;
            background: transparent;
            color: #FFF;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            div {
                display: flex;
                align-items: center; }
            img {
                cursor: pointer;
                margin-right: 10px;
                width: 25px;
                height: 25px;
                margin-right: 90px; } }
        .container {
            width: 85%;
            height: 90%;
            border-radius: 14px;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px;
            .row-left {
                width: 70%;
                height: 100%;
                background: transparent;
                padding: 12px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    overflow: hidden; }
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    overflow: hidden;
                    background: #000; } }
            .row-right {
                width: 30%;
                height: 100%;
                background: #f6f6f6;
                border: 1px solid #e9e9e9;
                border-radius: 14px;
                padding: 14px 14px 14px 14px;
                .fileName {
                    color: #121212;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-break: break-word; }
                .fileSize {
                    color: #404040;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 20px; }
                .fileType {
                    color: #404040;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 20px;
                    width: 80px;
                    text-align: center;
                    padding: 3px;
                    border-radius: 8px;
                    text-transform: capitalize;
                    background-color: #ffe29f; }
                .copy-url {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #121212;
                    background-color: #e0e0e0;
                    width: 100%;
                    height: 44px;
                    text-align: center;
                    border: 0px solid #b6b6b6;
                    border-radius: 8px;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 14px;
                    cursor: pointer;
                    transition: 0.3s ease-in;
                    &:hover {
                        font-weight: 600;
                        background-color: #c5c5c5;
                        transition: 0.3s ease-out; } }
                .download {
                    .download-link {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFF;
                        background-color: #121212;
                        width: 100%;
                        height: 44px;
                        text-align: center;
                        border: 1px solid #121212;
                        border-radius: 8px;
                        margin-bottom: 14px;
                        cursor: pointer;
                        transition: 0.3s ease-in;
                        font-size: 15px;
                        font-weight: 500;
                        &:hover {
                            color: #FFF;
                            font-weight: 600;
                            background-color: #121212;
                            transition: 0.3s ease-out; } } }
                .delete {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fd4c4c;
                    background-color: transparent;
                    width: 100%;
                    height: 44px;
                    text-align: center;
                    border: 0px solid #fd4c4c;
                    border-radius: 8px;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 14px;
                    cursor: pointer;
                    transition: 0.3s ease-in;
                    &:hover {
                        color: #fd4c4c;
                        font-weight: 600;
                        text-decoration: underline;
                        transition: 0.3s ease-out; } } } } }
    .storage-used {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        .label {
            color: #707070;
            font-size: 13px;
            font-weight: 500; }
        .used {
            color: #000;
            font-weight: 600;
            font-size: 12px; } }
    .uploading {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: absolute;
        bottom: 20px;
        right: 10px;
        .item {
            border-radius: 4px;
            border: 0.75px solid #D2D4D9;
            background: #FFF;
            box-shadow: 0px 0px 10px 0px rgba(229, 231, 237, 0.65);
            width: 255px;
            height: 48px;
            display: flex;
            text-transform: uppercase;
            align-items: center;
            margin-bottom: 10px;
            position: relative;
            .name {
                color: #292D32;
                font-size: 14px;
                font-weight: 500;
                width: 188px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 12px; }
            .size {
                color: #7D838C;
                font-size: 12px;
                font-weight: 500; } } }
    .closed {
        position: absolute;
        left: -10px;
        top: -8px;
        cursor: pointer; }
    .started {
        margin: 0px 8px;
        height: 22px;
        width: 22px;
        border: 4px solid #d1d5db;
        border-top-color: #21C082;
        border-left-color: #21C082;
        border-bottom-color: #21C082;
        border-right-color: #D3D8DF;
        border-radius: 50%;
        animation: spinner 1000ms linear infinite; }
    @keyframes spinner {
        from {
            transform: rotate(0deg); }
        to {
            transform: rotate(360deg); } }
    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        box-sizing: border-box;
        .search {
            font-size: 20px;
            font-weight: 500;
            color: #292D32;
            border-radius: 30px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            width: 520px;
            height: 44px;
            flex-shrink: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            img {
                width: 12px;
                height: 12px;
                margin-right: 10px; }
            input {
                color: #4E4E4E;
                font-size: 14px;
                font-weight: 400; } }
        .upload-button {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #121212;
            width: 109px;
            height: 36px;
            border-radius: 4px;
            margin-right: 20px;
            color: #FFF;
            img {
                height: 24px; } } }
    .info-panel {
        width: 0px;
        padding: 0px;
        border-left: 1px solid #D9D9D9;
        animation: expandInfoPanel 0.3s ease-out forwards;
        @keyframes expandInfoPanel {
            from {
                width: 0;
                padding: 0; }

            to {
                width: 450px;
                padding: 20px; } }
        .header {
            color: #78818C;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;
            margin-top: 12px; }
        .back {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 10px;
            label {
                margin-left: 10px;
                width: 190px;
                font-size: 20px; } }
        .size {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            margin-bottom: 22px;
            text-transform: capitalize;
            color: #121212;
            label {
                color: #78818C;
                font-size: 14px;
                font-weight: 500; } }
        .copy-url {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            margin-bottom: 22px;
            text-transform: capitalize;
            color: #121212;
            cursor: pointer;
            &:hover {
                color: #21C082;
                text-decoration: underline; } }
        .download {
            cursor: pointer;
            .download-link {
                font-size: 13px;
                font-weight: 500;
                display: flex;
                flex-direction: column;
                margin-bottom: 22px;
                text-transform: capitalize;
                color: #121212;
                &:hover {
                    color: #21C082;
                    text-decoration: underline; } } }
        .delete {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            margin-bottom: 22px;
            text-transform: capitalize;
            color: #fd4c4c;
            cursor: pointer;
            &:hover {
                color: #fd4c4c;
                text-decoration: underline; } }

        .pre-img {
            background: #d9d9d930;
            padding: 8px;
            border-radius: 6px;
            border: 1px solid #d9d9d9;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 165px; }
            video {
                height: 165px; }
            audio {
                height: 165px; } } }
    .contain {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100%;
        transition: width 0.3s ease-out; }
    .file-panel {
        height: calc(100vh - 140px);
        overflow-y: auto;
        width: 100%; }
    .placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 70vh;
        width: 100%;
        flex-wrap: wrap;
        overflow-y: auto;
        img {
            width: 100px;
            height: 100px; }
        div {
            margin-top: 15px;
            color: #292D32;
            font-size: 16px;
            font-weight: 500;
            width: 304px;
            text-align: center; } }
    .files {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        width: 100%;
        > * {
            flex-grow: 1;
            height: 200px; }
        &::after {
            content: '';
            min-width: 75px;
            flex-grow: 999999999;
            height: 0px; }
        .file {
            margin-left: 10px;
            margin-bottom: 10px;
            height: 200px;
            background-color: #dadce080;
            border-radius: 8px;
            overflow: hidden;
            .layer {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                background: rgba(0, 0, 0, 0.0); }
            .image:hover .layer {
                display: block; }
            .image:hover .checkbox {
                display: block; }
            .image:hover .options {
                display: flex; }
            .image {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                border-radius: 6px;
                overflow: hidden;
                min-width: 75px;
                height: 200px;
                .checkbox {
                    display: none;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 1;
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    padding: 2px;
                    background: #ffffffb8;
                    border: 1px solid #000;
                    box-shadow: 0 4px 30px #0000009e;
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    .checked {
                        background: #07C180;
                        width: 100%;
                        height: 100%;
                        border-radius: 4px; }
                    .unchecked {
                        background: #ffffffb8;
                        width: 100%;
                        height: 100%;
                        border-radius: 4px; } }
                img {
                    height: 100%;
                    max-width: 100%; }
                .options {
                    display: none;
                    position: absolute;
                    img {
                        height: 36px; } } } } }

    .upload-label {
        // padding: 10px
        cursor: pointer;
        border-radius: 6px;
        width: auto;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        // height: 96%
        margin: 10px; }
    .upload-active {
        border-radius: 8px;
        border: 1px solid #21C082;
        background: rgba(33, 192, 130, 0.10); }
    .image {
        min-width: 100%;
        max-width: 100%;
        height: 140px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%; }
    #file-comp {
        display: none; }
    .dd-notify {
        font-size: 14px;
        font-weight: 500;
        width: 220px;
        height: 48px;
        display: none;
        border-radius: 50px;
        align-items: center;
        justify-content: center;
        color: #FFF;
        background: #121212;
        position: absolute;
        bottom: 20px;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25); } }
