.order {
    .label-new {
        background: rgb(255, 228, 147);
        border-radius: 30px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        font-size: 12px;
        font-weight: 500; }
    .list {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #96A9C340;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        .order-status {
            display: flex;
            height: 60px;
            align-items: center;
            border-radius: 4px;
            .counts {
                position: absolute;
                margin-right: -30px;
                border-radius: 50px;
                top: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgb(255, 153, 49);
                border-radius: 4px;
                padding: 0px 8px;
                height: 25px;
                text-align: center;
                font-size: 14px;
                font-weight: bold; }
            .status {
                margin-right: 50px;
                font-size: 14px;
                letter-spacing: 0px;
                color: #4E4E4E;
                cursor: pointer;
                height: 100%;
                align-items: center;
                display: flex;
                font-weight: normal;
                position: relative; }
            .selected {
                font-weight: 800;
                cursor: default;
                color: #188FFC;
                border-bottom: 3px solid #188FFC; } }
        .delivery-slots {
            display: flex;
            align-items: center;
            height: 45px;
            font-size: 12px;
            flex-grow: 1;
            overflow-x: auto;
            margin-right: 10px;
            .slot {
                height: 26px;
                border: 1px solid #5E6774;
                background: #FFFFFF;
                border: 1px solid #5E6774;
                border-radius: 20px;
                display: flex;
                white-space: nowrap;
                align-items: center;
                justify-content: center;
                padding: 0px 14px;
                margin-right: 10px;
                cursor: pointer; }
            .selected {
                cursor: default;
                background: #121212;
                box-shadow: 0px 3px 6px #19233172;
                border: 1px solid #4a4e52;
                color: #FFF; } }
        .action-bar {
            height: 50px;
            // background: #f5f5f5
            // border-bottom-width: 1px
            // border-bottom-style: solid
            // border-bottom-color: #E7EEF7
            text-align: right;
            padding: 0px 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            .date-range {
                position: relative;
                background: #FFFFFF;
                border: 1px solid #ccc;
                border-radius: 5px;
                height: 40px;
                padding-left: 20px;
                margin: 0px 1px 0px 4px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                .ant-picker {
                    border: none; }
                .ant-picker-input {
                    width: 108px; }
                .ant-picker-range .ant-picker-active-bar {
                    height: 0px; } }
            .actions {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            .title {
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #404040;
                float: left;
                margin: 0px 10px;
                line-height: 2em; } } }
    .orders {
        border: 1px solid #D8DEE6;
        border-radius: 4px;
        margin: 10px 10px 0px 10px;
        .action-bar {
            display: flex;
            justify-content: center;
            .reject {
                cursor: pointer;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #000000;
                font: 12px AvenirMedium;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
                margin-right: 5px;
                border-radius: 4px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-items: center;
                padding: 0px 20px; }
            .accept {
                cursor: pointer;
                background: #000000 0% 0% no-repeat padding-box;
                font: 12px AvenirMedium;
                letter-spacing: 0px;
                color: #FFFFFF;
                margin-left: 5px;
                border-radius: 4px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-items: center;
                padding: 0px 20px; } }
        .header {
            background: #D8DEE63D;
            border-radius: 4px 4px 0px 0px;
            color: #1C3A66;
            font-size: 13px;
            border-radius: 4px 4px 0px 0px;
            padding: 8px 22px; }
        .details {
            padding: 14px 22px;
            background: #FFF;
            overflow-y: auto;
            height: 72vh;
            .no-data-found {
                color: #808080;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px; } } }
    .contains {
        margin: 10px 0px;
        .list {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 10px #96A9C340;
            border-radius: 5px;
            height: 100%;
            .action {
                display: flex;
                justify-content: space-between;
                padding: 10px 10px;
                .title {
                    font: 14px AvenirMedium;
                    font-weight: 900;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: capitalize;
                    margin: 0px;
                    min-height: 0px;
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    border-bottom-color: #FF9931;
                    border-bottom-style: solid;
                    border-bottom-width: 2px;
                    padding-right: 8px;
                    position: relative; }
                .counts {
                    position: absolute;
                    right: -18px;
                    border-radius: 50px;
                    top: -11px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(255, 153, 49);
                    height: 25px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    .line {
                        height: 2px;
                        background: #FF9931 0% 0% no-repeat padding-box;
                        border-radius: 40px;
                        margin-left: 10px;
                        width: 118%; } }
                .select {
                    background: #96A9C340 0% 0% no-repeat padding-box;
                    border-radius: 3px;
                    padding: 4px 8px;
                    font: 12px AvenirMedium;
                    letter-spacing: 0px;
                    color: #0076FF;
                    height: 20px; } }
            .order {
                overflow-y: auto; } } } }

.card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000029;
    border-radius: 5px;
    margin: 8px 8px 0px 0px;
    width: 100%;
    .info {
        padding: 0px 8px;
        .group {
            margin: 10px 0px;
            .initial {
                width: 20px;
                height: 20px;
                background: #E3FDF8 0% 0% no-repeat padding-box;
                float: left;
                border-radius: 30px;
                text-align: center;
                margin-right: 4px;
                font: 14px AvenirMedium;
                letter-spacing: 0px;
                color: #34E5C1;
                padding-top: 2px; }
            .name {
                font: 13px/18px AvenirMedium;
                float: left;
                padding-top: 3px; }
            .icn {
                float: right;
                img {
                    cursor: pointer; } }
            .serial-no {
                font: 13px/22px AvenirMedium;
                letter-spacing: -0.42px;
                color: #000000; }
            .view-details {
                background: #00BDFF 0% 0% no-repeat padding-box;
                border-radius: 3px;
                font: 10px/14px AvenirMedium;
                letter-spacing: 0px;
                color: #FFFFFF;
                padding: 4px; }
            .total, .quantity {
                font: 13px/22px AvenirMedium;
                font-weight: 900;
                span {
                    margin-left: 14px; }
                img {
                    position: absolute;
                    margin-top: 2px;
                    width: 12px; } }
            .date {
                color: #ADB7C3;
                font: 11px AvenirMedium;
                margin-top: 4px; }
            .address {
                background: #F8FBFF 0% 0% no-repeat padding-box;
                border-radius: 4px;
                padding: 4px;
                font: 13px AvenirMedium;
                font-weight: 300;
                letter-spacing: 0px;
                color: #071E3D; }
            .action-bar {
                display: flex;
                justify-content: center;
                .reject {
                    cursor: pointer;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #000000;
                    font: 12px AvenirMedium;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: capitalize;
                    margin-right: 5px;
                    border-radius: 4px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    padding: 0px 20px; }
                .accept {
                    cursor: pointer;
                    background: #000000 0% 0% no-repeat padding-box;
                    font: 12px AvenirMedium;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    margin-left: 5px;
                    border-radius: 4px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    padding: 0px 20px; } }
            .items {
                background: #F8FBFF 0% 0% no-repeat padding-box;
                border-radius: 4px;
                padding-bottom: 8px;
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 0px;
                color: #071E3D;
                display: flex;
                flex-direction: column;
                .item {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 2px 8px;
                    border-bottom: 1px solid #EBEEF1; } }
            .total-price {
                background: #F8FBFF 0% 0% no-repeat padding-box;
                border-radius: 4px;
                text-align: right;
                margin-right: 8px;
                .label {
                    font: 12px AvenirMedium;
                    letter-spacing: 0px;
                    color: #697B95;
                    text-transform: capitalize;
                    margin-right: 10px; }
                .value {
                    font: 12px AvenirMedium;
                    letter-spacing: 0px;
                    color: #000000;
                    text-transform: capitalize; } } } } }

.order-view {
    padding: 15px;
    .header {
        font-size: 12px;
        .link {
            cursor: pointer;
            font: 14px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD; }
        .head {
            font: 14px AvenirMedium;
            letter-spacing: 0px;
            color: #000000; } }
    .title {
        margin-top: 6px;
        .action-bar {
            display: flex;
            justify-content: flex-end;
            .reject {
                cursor: pointer;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #C70039;
                font: 12px AvenirMedium;
                letter-spacing: 0px;
                color: #C70039;
                text-transform: capitalize;
                margin-right: 5px;
                border-radius: 4px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-items: center;
                padding: 0px 20px; }
            .accept {
                cursor: pointer;
                background: #000000 0% 0% no-repeat padding-box;
                font: 12px AvenirMedium;
                letter-spacing: 0px;
                color: #FFFFFF;
                margin-left: 5px;
                border-radius: 4px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-items: center;
                padding: 0px 20px; } }
        .lable {
            padding-top: 6px;
            font: 18px AvenirMedium;
            font-weight: 900;
            letter-spacing: 0px;
            color: #000000;
            float: left; }
        .action {
            float: right;
            display: flex;
            .reject {
                background: transparent;
                margin-right: 18px;
                cursor: pointer;
                font: 12px/22px AvenirMedium;
                letter-spacing: 0px;
                color: #697B95;
                text-transform: capitalize;
                border: none;
                box-shadow: 0px 0px 0px #96A9C340; }
            .accept {
                cursor: pointer;
                font: 12px/22px AvenirMedium;
                letter-spacing: -0.38px;
                color: #FFFFFF;
                text-transform: capitalize;
                background: #000000 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 12px #96A9C340;
                border-radius: 4px; } } }
    .contains {
        margin-top: 18px;
        .info {
            margin: 0px 5px 0px 5px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 10px #96A9C340;
            border-radius: 5px;
            height: 100%;
            padding: 10px 14px;
            .title {
                font: 16px/22px AvenirMedium;
                font-weight: 900;
                letter-spacing: 0px;
                color: #697B95; }
            .divider {
                height: 1px;
                background: #E7EEF7;
                margin: 16px -14px; }
            .group {
                display: flex;
                justify-content: space-between;
                margin-top: 10px; }
            .label {
                font: 14px/22px AvenirMedium;
                letter-spacing: -0.45px;
                color: #697B95; }
            .value {
                font: 14px/22px AvenirMedium;
                letter-spacing: -0.45px;
                color: #000000;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
            .price {
                font: 15px/17px AvenirMedium;
                letter-spacing: 0px;
                color: #000000;
                text-align: right; } }
        .o_items {
            margin: 0px 5px 0px 5px;
            height: 100%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 10px #96A9C340;
            border-radius: 5px;
            padding: 10px 14px;
            .title {
                font: 16px/22px AvenirMedium;
                font-weight: 900;
                letter-spacing: 0px;
                color: #697B95; }
            .divider {
                height: 1px;
                background: #E7EEF7;
                margin: 5px 0px; }
            .value {
                font: 14px/22px AvenirMedium;
                letter-spacing: -0.45px;
                margin: 9px 0px;
                color: #000000;
                .product {
                    // box-shadow: 0px 0px 3px #071E3D3B
                    // border: 1px solid #E7EEF7
                    border-radius: 4px;
                    margin-right: 22px;
                    overflow: hidden;
                    width: 65px;
                    height: 65px;
                    padding: 0px;
                    img {
                        width: 65px;
                        height: 65px;
                        object-fit: cover; } } }
            .price-summary {
                bottom: 10px;
                position: absolute;
                width: 100%;
                .label {
                    font: 15px/17px AvenirMedium;
                    letter-spacing: 0px;
                    color: #96A9C3; }
                .value {
                    font: 15px/17px AvenirMedium;
                    letter-spacing: 0px;
                    color: #96A9C3;
                    text-align: right; } } } } }
.delivery-execs {
    .ant-drawer-body {
        background: #FFF;
        padding: 0px;
        overflow: hidden; }
    .header {
        padding: 24px;
        font-size: 20px;
        color: #000;
        border-bottom: 1px solid #e5e5e5;
        background: #CBCBCB26;
        .link {
            cursor: pointer;
            font: 14px/19px AvenirMedium;
            letter-spacing: 0px;
            color: #11A1FD; }
        .head {
            font: 14px/19px AvenirMedium;
            letter-spacing: 0px;
            color: #000000; }
        .title {
            font: normal normal 900 18px/25px AvenirMedium;
            letter-spacing: 0px;
            color: #000000; } }
    .content {
        padding: 24px;
        color: #000;
        width: 100%;
        overflow-y: auto;
        // height: 100%
        .details {
            margin-top: 20px;
            margin-bottom: 20px;
            .ant-radio-wrapper {
                color: #000; }
            .group {
                margin: 16px 0px;
                .label {
                    font: 14px AvenirMedium;
                    letter-spacing: 0px;
                    color: #4e4e4e;
                    margin-bottom: 10px; }
                .value {
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #ADB7C3;
                    border-radius: 4px;
                    resize: none;
                    width: 100%;
                    img {
                        cursor: pointer; } } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; } }
    .ant-drawer-footer {
        height: 66px;
        border-top: 1px solid #E5E5E5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .error {
            color: #ED1536;
            font-size: 15px;
            letter-spacing: 0px;
            font-weight: 800;
            float: left;
            padding: 12px 26px; }
        .cancel, .save {
            height: 100%;
            width: 100px;
            font-size: 14px;
            border-radius: 4px; }
        .save {
            background: #000000 0% 0% no-repeat padding-box;
            color: #fff; }
        .cancel {
            margin-right: 16px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #4E4E4E;
            color: #4E4E4E; } } }
