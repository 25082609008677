@font-face {
    font-family: AvenirLight;
    src: url(../../fonts/Avenir/AvenirLTStd-Light.otf); }
@font-face {
    font-family: AvenirNext;
    src: url(../../fonts/Avenir/AvenirNext-Medium.otf); }
@font-face {
    font-family: AvenirBlack;
    src: url(../../fonts/Avenir/AvenirLTStd-Black.otf); }
@font-face {
    font-family: AvenirBook;
    src: url(../../fonts/Avenir/AvenirLTStd-Book.otf); }
@font-face {
    font-family: AvenirHeavy;
    src: url(../../fonts/Avenir/AvenirLTStd-Heavy.otf); }
@font-face {
    font-family: AvenirMedium;
    src: url(../../fonts/Avenir/AvenirLTStd-Medium.otf); }
@font-face {
    font-family: AvenirOblique;
    src: url(../../fonts/Avenir/AvenirLTStd-Oblique.otf); }
@font-face {
    font-family: AvenirRoman;
    src: url(../../fonts/Avenir/AvenirLTStd-Roman.otf); }

body, * {
    font-family: AvenirNext; }

// *:not(input)
//     -moz-user-select: -moz-none
//     -khtml-user-select: none
//     -webkit-user-select: none
//     -ms-user-select: none
//     user-select: none
.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black
    & .tooltiptext {
        text-transform: uppercase;
        visibility: hidden;
        width: 120px;
        background-color: #000000bb;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: -5px;
        left: 53px; }
    & .tooltiptext::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #000000bb transparent transparent; }
    &:hover .tooltiptext {
        visibility: visible; } }

::-webkit-scrollbar {
    width: 4px;
    height: 2px; }

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #FFF; }

::-webkit-scrollbar-thumb {
    background-color: #000000;
    outline: 2px solid #000000; }

.carousel .slide {
    background: #FFF !important; }
.row-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px; }
.col-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
.sp_label {
    height: 19px;
    text-align: left;
    font: 14px AvenirMedium;
    letter-spacing: 0px;
    color: #A8A8A8;
    margin-bottom: 10px; }
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #121212;
    background: #FFF; }
.ant-input[disabled] {
    color: #121212; }
.ant-input:focus, .ant-input-focused {
    border: 1px solid #121212;
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
.ant-input-affix-wrapper:hover {
    border: 1px solid #ccc; }
.ant-input-affix-wrapper > input.ant-input {
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 20px; }
    @media screen and (min-width: 1400px) {
        height: 30px; } }
.ant-switch-checked {
    background: #121212; }
.ant-input-affix-wrapper {
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    align-items: center;
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 30px; }
    @media screen and (min-width: 1400px) {
        height: 40px; } }
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border: 1px solid #ccc;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 0px rgba(24, 144, 255, 0.2); }
input {
    border-radius: 5px;
    background: #ffffff;
    width: 100%;
    color: #000;
    border: none;
    outline: none !important;
    // letter-spacing: 2px
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 30px; }
    @media screen and (min-width: 1400px) {
        height: 40px; } }
.ant-input {
    border-radius: 5px;
    background: #ffffff;
    width: 100%;
    color: #000;
    outline: none !important;
    // letter-spacing: 2px
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 30px; }
    @media screen and (min-width: 1400px) {
        height: 40px; } }
input::-webkit-input-placeholder {
    color: #808A96; }
input:-ms-input-placeholder {
    color: #808A96; }
input::placeholder {
    color: #808A96; }
.ant-slider-track, .ant-slider:hover .ant-slider-track {
    background-color: #000; }
.ant-slider-handle, .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open), .ant-slider-handle {
    border-color: #000; }
.ant-slider-handle:focus {
    border-color: #000;
    box-shadow: 0 0 0 5px #0000001f; }
.ant-radio-inner::after {
    background-color: #000; }
.ant-radio-checked .ant-radio-inner, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner, .ant-radio-inner {
    border-color: #000; }
.ant-picker .ant-picker-input >input[disabled] {
    background: #fff; }
.ant-modal .ant-modal-content {
    padding: 0px; }
.rm-border {
    border: 1px solid #ccc; }
.toggle-opt {
    background: rgba(0, 0, 0, 0.45) !important; }
.toggle-opt.ant-switch.ant-switch-checked {
    background: rgba(0, 0, 0, 1) !important; }
textarea.ant-input {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(203, 203, 203);
    border-radius: 5px;
    font-size: 15px;
    letter-spacing: 0px;
    color: #121212;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 119px; }
.sp_btn {
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
    color: #fff;
    background: #000000 0% 0% no-repeat padding-box;
    padding: 0px 22px;
    font-weight: 500;
    letter-spacing: -0.032em;
    box-shadow: 0px 0px 12px #96a9c340;
    border: 0px;
    line-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 30px; }
    @media screen and (min-width: 1400px) {
        height: 38px; } }
.ant-input-password {
    padding: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0px 12px !important;
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 32px !important; }
    @media screen and (min-width: 1400px) {
        height: 42px !important; }
    input {
        margin-top: 0px;
        border: none;
        border-radius: 5px; }
    @media screen and (min-width: 900px) and (max-width: 1400px) {
        height: 30px; }
    @media screen and (min-width: 1400px) {
        height: 40px; } }
.ant-picker-focused {
    border-color: #000;
    border-right-width: 0px !important;
    outline: 0;
    box-shadow: 0 0 0 0px #000; }
.ant-picker-now a {
    color: #000; }
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #efefef; }
.ant-picker-ok button, .ant-picker-ok button:hover {
    background: #000;
    border-color: #000; }
.ant-picker:hover {
    border-color: #000; }
.ant-picker {
    border: none;
    padding: 0px;
    border-bottom: 1px solid #121212;
    border-radius: 0px; }
.ant-picker .ant-picker-clear, .ant-picker .ant-picker-suffix {
    display: none; }
.ant-picker input {
    font-size: 16px; }
.ant-checkbox-wrapper {
    font-size: 16px;
    color: #848484; }
.ant-popover-inner {
    // width: 220px
    // height: 85px
    background: #FFFFFF;
    box-shadow: 0px 3px 16px #3D3D4621;
    border-radius: 7px;
    font-size: 13px;
    letter-spacing: 0px;
    color: #3D3D46; }
.ant-checkbox-inner {
    width: 18px;
    height: 18px; }
.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    background-color: #000;
    border-color: #000; }
.ant-select-dropdown {
    box-shadow: 0px 8px 24px #00000029;
    border: 1px solid #999CA0;
    border-radius: 12px;
    margin-top: 20px;
    .ant-select-item {
        min-height: 48px;
        padding-top: 12px; }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: #F5F5F5;
        color: #000; } }
.ant-select {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    // border: 1px solid #1A1A1A
    // border-radius: 5px
    // height: 44px
    .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        border-radius: 5px !important;
        height: 40px !important; }
    .ant-select-multiple .ant-select-selector, .ant-select-selector {
        border-radius: 5px;
        display: flex;
        align-items: center;
        // overflow: hidden
        // justify-content: center
        @media screen and (min-width: 900px) and (max-width: 1400px) {
            min-height: 30px !important; }
        @media screen and (min-width: 1400px) {
            min-height: 40px !important; } } }
// ::selection
//     color: #fff
//     background: #fff
.filters {
    .ant-input-suffix {
        @media screen and (min-width: 900px) and (max-width: 1400px) {
            line-height: 1; }
        @media screen and (min-width: 1400px) {
            line-height: 2; }
        img {
            @media screen and (min-width: 900px) and (max-width: 1400px) {
                width: 15px;
                height: 20px; }
            @media screen and (min-width: 1400px) {
                width: 20px;
                height: 24px; } } } }
.edit {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #96A9C340;
    border: 1px solid #F8F8F8;
    border-radius: 4px;
    font-size: 14px;
    letter-spacing: -0.38px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 127px; }
.cancel {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #4E4E4E;
    border-radius: 4px;
    text-transform: capitalize;
    width: 127px;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4E4E4E; }
.save {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #96A9C340;
    border: 1px solid #F8F8F8;
    border-radius: 4px;
    font-size: 14px;
    letter-spacing: -0.38px;
    color: #FFFFFF;
    text-transform: capitalize;
    width: 127px; }
.ant-drawer-footer {
    height: 66px;
    border-top: 1px solid #E5E5E5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .add-price {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 15px #758BFD63;
            border-radius: 4px;
            font: 12px AvenirMedium;
            letter-spacing: 0px;
            color: #000000;
            width: 100%;
            .cancel, .save {
                height: 100%;
                width: 100px;
                font-size: 14px;
                border-radius: 4px; }
            .save {
                background: #000000 0% 0% no-repeat padding-box;
                color: #fff; }
            .cancel {
                margin-right: 16px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #4E4E4E;
                color: #4E4E4E; } } } }

.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 9999;
    .animation {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            & div {
                position: absolute;
                top: 33px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: #fff;
                animation-timing-function: cubic-bezier(0, 1, 1, 0); }
            & div:nth-child(1) {
                left: 8px;
                animation: lds-ellipsis1 0.6s infinite; }
            & div:nth-child(2) {
                left: 8px;
                animation: lds-ellipsis2 0.6s infinite; }
            & div:nth-child(3) {
                left: 32px;
                animation: lds-ellipsis2 0.6s infinite; }
            & div:nth-child(4) {
                left: 56px;
                animation: lds-ellipsis3 0.6s infinite; } }
        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0); }

            100% {
                transform: scale(1); } }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1); }

            100% {
                transform: scale(0); } }
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0); }

            100% {
                transform: translate(24px, 0); } } } }
.drawer-view {
    .ant-picker.ant-picker-disabled {
        background: #FFF; }
    .ant-drawer-body, .ant-drawer-footer {
        background: #FFF; }
    .ant-drawer-body {
        padding: 0px;
        overflow: hidden; }
    .header {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #E5E5E5;
        padding: 0px 17px;
        .title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 16px; }
        .arrow {
            cursor: pointer;
            color: #000;
            font-size: 24px; } }
    .content {
        width: 100%;
        height: 88vh;
        overflow-y: auto;
        padding: 6px 40px;
        .group {
            margin: 16px 0px;
            .date-range {
                position: relative;
                background: #FFFFFF;
                border: 1px solid #ccc;
                border-radius: 5px;
                height: 40px;
                padding-left: 20px;
                display: flex;
                justify-content: center;
                .ant-picker {
                    border: none; }
                .ant-picker-input {
                    width: 108px; }
                .ant-picker-range .ant-picker-active-bar {
                    height: 0px; } }
            .label {
                height: 19px;
                text-align: left;
                font-size: 16px;
                letter-spacing: 0px;
                color: #4E4E4E;
                margin-bottom: 8px; }
            .value {
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #ADB7C3;
                border-radius: 4px;
                resize: none;
                width: 100%;
                img {
                    cursor: pointer; } } }
        .line {
            border: 1px solid #E7EEF7;
            width: 100%;
            height: 0px; }
        .action-bar {
            text-align: left;
            padding-left: 40px;
            .error {
                color: #ED1536;
                font-size: 15px;
                letter-spacing: 0px;
                font-weight: 800;
                float: left;
                padding: 12px 26px; } } } }
